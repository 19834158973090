import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Accordion } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "@mui/material/Pagination";
// import Pagination from "react-bootstrap/Pagination";
import Stack from "@mui/material/Stack";
import moment from "moment";
import dostsIcon from "../../Assests/dostsIcon.svg";
import deleteIcon from "../../Assests/deleteIcon.svg";
import editIcon from "../../Assests/editIcon.svg";
import viewDetailsIcon from "../../Assests/viewDetailsIcon.svg";
import Navbar from "../Navbar/Navbar";

const Datatable = ({ setCodeDataCall, codeDataCall }) => {
  const history = useHistory();
  const val = localStorage.getItem("accessToken");
  const [loader, setLoader] = useState(false);
  const [accessCodesData, setAccessCodesData] = useState([]);
  const [orderField, setOrderField] = useState("createdAt");
  const [orderDirection, setOrderDirection] = useState("-1");
  const [usdPrice, setUsdPrice] = useState(0);
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [tomiPrice, setchange24hr1] = useState(0);

  const [totalPages, setTotalPages] = useState(1);

  const [data, setData] = useState([]);

  const maxVisiblePages = 5; // Maximum number of visible page numbers
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the start and end indices for visible page numbers

  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

  // Function to handle page click
  const handlePageClick = (page) => {
    setCurrentPage(page);
    // You can perform additional actions here, such as fetching data for the selected page.
  };

  useEffect(() => {
    get_ApiKeys();

    axios
      .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
      .then((res) => {
        setUsdPrice(res?.data?.USD);
        // setEthUsd(res.data);
      });
  }, []);

  const get_ApiKeys = async () => {
    try {
      const response = await axios({
        method: "get",
        url: "https://tomipay-staging.tomi.com/users/private-keys",
      });
      GetCoinsList(response?.data?.keys?.AddCustomToken);
      // console.log(
      //   response?.data?.keys?.AddCustomToken,
      //   "response?.data?.keys?.AddCustomToken"
      // );
      // setApiKey(response?.data?.keys?.AddCustomToken);
    } catch (error) {
      // console.log("Error in get_ApiKeys:", await error.response);
      throw error;
    }
  };

  const GetCoinsList = async (e) => {
    let coingeckoList = await axios.get(
      `https://pro-api.coingecko.com/api/v3/coins/list?include_platform=true&x_cg_pro_api_key=${e}`
    );
    let dumArr = coingeckoList.data.filter((i) => {
      // console.log(i);
      if (i?.id === "tominet") {
        return i;
      }
    });
    // console.log(dumArr[0].id, "dumArr");
    coindata(e, dumArr[0].id);
  };

  const coindata = async (e, d) => {
    let res = await axios.get(
      `https://pro-api.coingecko.com/api/v3/coins/${d}?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false&x_cg_pro_api_key=${e}`
    );
    // console.log(res, "rsdsdes");
    // dta(res)
    let change24hr = res?.data.market_data?.price_change_percentage_24h;
    let curentprice = res.data?.market_data?.current_price?.usd;
    let imageurl = res.data?.image.small;
    setchange24hr1(change24hr);
    // console.log(change24hr, curentprice, imageurl, "fdgdsfgdssf");
  };

  useEffect(() => {
    if (val && val !== "" && val !== undefined) {
      setCodeDataCall(() => accessCodesList);
      accessCodesList();
    } else {
      history.push("/");
    }
  }, [offset, orderField, orderDirection]);

  const accessCodesList = async () => {
    setLoader(true);
    setAccessCodesData([]);
    var config = {
      method: "get",
      url: `${Api_Url}/access-codes?limit=${10}&offset=${offset}&orderField=${orderField}&orderDirection=${orderDirection}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response?.data?.data;
        setTotalPages(resData?.pages);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
        setAccessCodesData(resData?.accessCodes);
        setLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }
        setLoader(false);
      });
  };

  const publishUnpublishCode = async (id) => {
    // setLoader(true);
    const config = {
      method: "patch",
      url: Api_Url + "/access-codes/" + id,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        // setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
        }
        setLoader(false);
      });
  };

  const MoveNext = (item) => {
    localStorage.setItem("current", JSON.stringify(item));
    history.push("/leader?owner=" + item?.ownerAddress);
  };

  useEffect(() => {
    displayData();
  }, []);

  const displayData = async () => {
    var data = JSON.stringify({
      query: `query MyQuery {
                investments(
                  orderDirection: desc
                  first: 10
                  orderBy: PurchaseTime
                ) {
                    totalTomi
                    token
                    ownerFunds
                    isClaimed
                    id
                    currentIndex
                    code_userPercentage
                    code_ownerPercentage
                    code_ownerAddress
                    code_code
                    ReleaseTime
                    PurchaseTime
                    By
                    Bonus
                    trxHash
                    AmountTomi
                    AmountSpent
                }
              }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/41280/tomi-china/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        setData(response?.data?.data?.investments);
      })
      .catch(function (error) {
        console.log(error, "error");
        // setstackingTime('')
      });
  };

  return (
    <>
      <Navbar codeDataCall={codeDataCall} />
      <section className="datatable leader-home-datetable-tableeee new-table-style-globally">
        <div class="table-responsivecolorbg">
          <div className="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div
                      className="parent-tag"
                      onClick={() => {
                        setOrderField("createdAt");
                        setOrderDirection(() => {
                          if (orderDirection == -1) return 1;
                          else return -1;
                        });
                      }}
                    >
                      Date & Time{" "}
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag"
                      onClick={() => {
                        setOrderField("name");
                        setOrderDirection(() => {
                          if (orderDirection == -1) return 1;
                          else return -1;
                        });
                      }}
                    >
                      Name
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag"
                      onClick={() => {
                        setOrderField("ownerAddress");
                        setOrderDirection(() => {
                          if (orderDirection == -1) return 1;
                          else return -1;
                        });
                      }}
                    >
                      Wallet Address
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>

                  <th>
                    <div
                      className="parent-tag"
                      onClick={() => {
                        setOrderField("code");
                        setOrderDirection(() => {
                          if (orderDirection == -1) return 1;
                          else return -1;
                        });
                      }}
                    >
                      Access Code
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  {/* <th>
                  <div
                    className="parent-tag"
                    onClick={() => {
                      setOrderField("createdAt");
                      setOrderDirection(() => {
                        if (orderDirection == -1) return 1;
                        else return -1;
                      });
                    }}
                  >
                    Created On{" "}
                    <div className="filter-btn">
                      <img
                        src="\Assets\tomitoken-assets\tableup.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <img
                        src="\Assets\tomitoken-assets\tabledown.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="parent-tag"
                    onClick={() => {
                      setOrderField("startDate");
                      setOrderDirection(() => {
                        if (orderDirection == -1) return 1;
                        else return -1;
                      });
                    }}
                  >
                    Start Date{" "}
                    <div className="filter-btn">
                      <img
                        src="\Assets\tomitoken-assets\tableup.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <img
                        src="\Assets\tomitoken-assets\tabledown.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="parent-tag"
                    onClick={() => {
                      setOrderField("endDate");
                      setOrderDirection(() => {
                        if (orderDirection == -1) return 1;
                        else return -1;
                      });
                    }}
                  >
                    End Date{" "}
                    <div className="filter-btn">
                      <img
                        src="\Assets\tomitoken-assets\tableup.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <img
                        src="\Assets\tomitoken-assets\tabledown.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </th> */}
                  <th>
                    <div className="parent-tag forcflexcenter tomi-purchased">
                      tomi Purchased
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag"
                      onClick={() => {
                        setOrderField("isActive");
                        setOrderDirection(() => {
                          if (orderDirection == -1) return 1;
                          else return -1;
                        });
                      }}
                    >
                      Owner %
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag"
                    // onClick={() => {
                    //   setOrderField("isActive");
                    //   setOrderDirection(() => {
                    //     if (orderDirection == -1) return 1;
                    //     else return -1;
                    //   });
                    // }}
                    >
                      Users %
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag total-purchases">
                      Total Purchases
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  {/* <th>
                    <div className="parent-tag">Status</div>
                  </th> */}
                  <th>
                    <div className="parent-tag">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {accessCodesData.length > 0 ? (
                  accessCodesData.map((item, index) => {
                    let ethConv = 0;
                    let tomiConv = 0;

                    let ownerEth = 0;
                    let userTomi = 0;
                    if (usdPrice) {
                      ethConv = parseFloat(item?.totalEth) * usdPrice;

                      ownerEth =
                        ((parseFloat(item?.totalUsdt) + parseFloat(ethConv)) *
                          (item?.ownerPercentage / 10)) /
                        100;
                    }
                    if (tomiPrice) {
                      // tomiConv = parseFloat(item?.totalTomi) * tomiPrice;

                      userTomi =
                        (parseFloat(item?.totalTomi) *
                          (item?.userPercentage / 10)) /
                        100;
                    }
                    return (
                      <>
                        <tr>
                          {/* <td>{item?.code}</td>
                          <td>
                            {item?.name} */}
                          {/* {item?.ownerAddress} */}
                          {/* </td> */}
                          {/* <td className="wallet-address-td">
                            <p>{item?.ownerAddress}</p>
                          </td>
                          <td>{(item?.ownerPercentage / 10).toFixed(2)}%</td>
                          <td>{(item?.userPercentage / 10).toFixed(2)}%</td> */}

                          {/* <td>11.20%</td> */}
                          {/* <td>
                          {moment(item?.createdAt)
                            .utc()
                            .format("DD/MM/YYYY hh:mm")}{" "}
                          UTC
                        </td>
                        <td>
                          {moment(item?.startDate)
                            .utc()
                            .format("DD/MM/YYYY hh:mm")}{" "}
                          UTC
                        </td>
                        <td>
                          {moment(item?.endDate)
                            .utc()
                            .format("DD/MM/YYYY hh:mm")}{" "}
                          UTC
                        </td> */}
                          {/* <td>
                            {item?.totalUsed > 0 ? (
                              <span className="dark">
                                <div className="d-flex justify-content-center">
                                  {item?.totalUsed}
                                </div>
                                <div className="d-flex justify-content-center">
                                  ({item?.totalEth?.toFixed(4)} ETH +{" "}
                                  {item?.totalUsdt?.toFixed(2)} USDT)
                                </div>
                              </span>
                            ) : (
                              <div className="d-flex justify-content-center">
                                0
                              </div>
                            )}
                          </td> */}

                          {/* <div className="dropdown dost-buttons-wrapper">
                              <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={dostsIcon} alt="dostsIcon" />
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li onClick={() => MoveNext(item)}> */}
                          {/* <a className="dropdown-item" href="#"> */}
                          {/* <Link to={'/leader'} className="dropdown-item">
                                    <img src={viewDetailsIcon} alt="viewDetailsIcon" />
                                    View Details
                                  </Link> */}
                          {/* </a> */}
                          {/* </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <img src={editIcon} alt="editIcon" />
                                    Edit</a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <img src={deleteIcon} alt="deleteIcon" />
                                    Delete</a>
                                </li>
                              </ul>
                            </div>
                          </td> */}
                          {/* <td>
                            <div class="fytgyugy">
                              <input
                                type="checkbox"
                                class="checkbox"
                                id={item?._id}
                                defaultChecked={item?.isActive}
                                onChange={() => publishUnpublishCode(item?._id)}
                              />
                              <label class="switch" for={item?._id}>
                                <span class="slider"></span>
                              </label>
                            </div>
                          </td> */}
                          <td>
                            <div className="table-inner">
                              <h6>
                                {" "}
                                {moment(item?.createdAt)
                                  .utc()
                                  .format("DD/MM/YYYY")}
                              </h6>
                              <p>
                                {" "}
                                {moment(item?.createdAt)
                                  .utc()
                                  .format("hh:mm")}{" "}
                                UTC
                              </p>
                            </div>
                          </td>
                          <td>{item?.name}</td>
                          <td>
                            <h5>
                              <div className="wallet-address-break">
                                {item?.ownerAddress}
                              </div>
                              <img
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    item?.ownerAddress
                                  );
                                }}
                                src="\Assets\navbar-assets\copy-icon.svg"
                                alt="img"
                                className="img-fluid ms-3"
                              />
                            </h5>
                          </td>
                          <td>{item?.code}</td>

                          <td>{item?.totalTomi?.toFixed()} TOMI</td>
                          <td>
                            <div className="table-inner">
                              <h6>{item?.ownerPercentage / 10}%</h6>
                              <p>{ownerEth?.toFixed(2)} USDT</p>
                            </div>
                          </td>
                          <td>
                            <div className="table-inner">
                              <h6>{item?.userPercentage / 10}%</h6>
                              <p>{userTomi.toFixed(4)} TOMI</p>
                            </div>
                          </td>
                          <td>
                            <div className="table-inner">
                              {item?.totalUsed > 0 ? (
                                <span className="dark">
                                  <h6 className="d-flex justify-content-center">
                                    {item?.totalUsed}
                                  </h6>
                                  <p className="d-flex justify-content-center">
                                    ({item?.totalEth?.toFixed(4)} ETH +{" "}
                                    {item?.totalUsdt?.toFixed(2)} USDT)
                                  </p>
                                </span>
                              ) : (
                                <div className="d-flex justify-content-center">
                                  0
                                </div>
                              )}
                            </div>
                          </td>
                          {/* <td>
                            <div class="fytgyugy">
                              <input
                                type="checkbox"
                                class="checkbox"
                                id={item?._id}
                                defaultChecked={item?.isActive}
                                onChange={() => publishUnpublishCode(item?._id)}
                              />
                              <label class="switch" for={item?._id}>
                                <span class="slider"></span>
                              </label>
                            </div>
                          </td> */}
                          <td>
                            <div className="dropdown dost-buttons-wrapper">
                              <button
                                className="btn dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src={dostsIcon} alt="dostsIcon" />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li className="active-toggle-buttonnnn ">
                                  <a className="dropdown-item">
                                    <p>Active</p>
                                    <div class="fytgyugy">
                                      <input
                                        type="checkbox"
                                        class="checkbox"
                                        id={item?._id}
                                        defaultChecked={item?.isActive}
                                        onChange={() => publishUnpublishCode(item?._id)}
                                      />
                                      <label class="switch" for={item?._id}>
                                        <span class="slider"></span>
                                      </label>
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item uvcuvvudvcucdvdvcvcdu"
                                    href="#"
                                  >
                                    <Link
                                      onClick={() => MoveNext(item)}
                                      // to={"/leader"}
                                      className="dropdown-item"
                                    >
                                      <img
                                        src={viewDetailsIcon}
                                        alt="viewDetailsIcon"
                                      />
                                      View Details
                                    </Link>
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <img src={editIcon} alt="editIcon" />
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <img src={deleteIcon} alt="deleteIcon" />
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : loader ? (
                  <tr>
                    <td colSpan="8" className="text-center">
                      <div className="text-center">
                        {<Spinner animation="border" variant="info" />}
                        {/* <h4>No Categories</h4> */}
                      </div>
                    </td>
                  </tr>
                ) : (
                  <p>No Records</p>
                )}
              </tbody>
            </table>
          </div>
          <div className="accmblview d-none">
            <div className="accmainheader">
              <h6 className="accmblviewheadi">Date</h6>
              <h6 className="accmblviewheadi">Name</h6>
              <h6 className="accmblviewheadi">TOMI</h6>
            </div>

            {accessCodesData.length > 0 ? (
              accessCodesData.map((item, index) => {
                return (
                  <>
                    <Accordion defaultActiveKey={-1}>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          <div className="accheadermain">
                            <p className="acctext">
                              {moment(item?.createdAt)
                                .utc()
                                .format("DD/MM/YYYY")}{" "}
                            </p>
                          </div>
                          <div className="accheadermain">
                            <p className="acctext">{item?.name}</p>
                          </div>
                          <div className="accheadermain">
                            <p className="acctext">
                              {(item?.totalTomi).toFixed()} TOMI
                            </p>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="acctexts">
                            <h6 className="textleft">Access Code</h6>
                            {/* <p className="textright">{item?.ownerAddress}</p> */}
                            <p className="textright">{item?.code}</p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Wallet Address</h6>
                            <p className="textright">
                              {/* {(item?.ownerPercentage / 10).toFixed(2)} */}
                              {item?.ownerAddress?.slice(0, 14) + "..."}
                              <img
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    item?.ownerAddress
                                  );
                                }}
                                style={{ cursor: 'pointer' }}
                                src="\Assets\navbar-assets\copy-icon.svg"
                                alt="img"
                                className="img-fluid ms-1"
                              />
                            </p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Date & Time </h6>
                            <p className="textright">
                              {" "}
                              {moment(item?.createdAt)
                                .utc()
                                .format("DD/MM/YYYY")}
                              <p>
                                {" "}
                                {moment(item?.createdAt)
                                  .utc()
                                  .format("hh:mm")}{" "}
                                UTC
                              </p>
                            </p>
                          </div>

                          <div className="acctexts">
                            <h6 className="textleft">tomi Purchased</h6>
                            <p className="textright">
                              {/* ({(item?.userPercentage / 10).toFixed(2)}) */}
                              {item?.totalTomi?.toFixed()} TOMI
                            </p>
                          </div>
                          {/* <div className="acctexts">
                          <h6 className="textleft">Created On</h6>
                          <p className="textright">
                            {" "}
                            {moment(item?.createdAt)
                              .utc()
                              .format("DD/MM/YYYY hh:mm")}{" "}
                            UTC
                          </p>
                        </div>
                        <div className="acctexts">
                          <h6 className="textleft">Start Date</h6>
                          <p className="textright">
                            {moment(item?.startDate)
                              .utc()
                              .format("DD/MM/YYYY hh:mm")}{" "}
                            UTC
                          </p>
                        </div>
                        <div className="acctexts">
                          <h6 className="textleft">End Date</h6>
                          <p className="textright">
                            {moment(item?.endDate)
                              .utc()
                              .format("DD/MM/YYYY hh:mm")}{" "}
                            UTC
                          </p>
                        </div> */}
                          {/* <div className="acctexts">
                            <h6 className="textleft">Amount Spent</h6>
                            <p className="textright"> */}
                          {/* {item?.totalUsed > 0 ? (
                                <span className="dark">
                                  {item?.totalUsed} ({item?.totalEth?.toFixed(4)}{" "}
                                  ETH + {item?.totalUsdt?.toFixed(2)} USDT)
                                </span>
                              ) : (
                                0
                              )} */}
                          {/* 2.5 ETH
                            </p>
                          </div> */}
                          {/* <div className="acctexts">
                            <h6 className="textleft">Action</h6> */}
                          {/* <div class="fytgyugy">
                            <input
                              type="checkbox"
                              class="checkbox"
                              id={item?._id}
                              defaultChecked={item?.isActive}
                              onChange={() => publishUnpublishCode(item?._id)}
                            />
                            <label class="switch" for={item?._id}>
                              <span class="slider"></span>
                            </label>
                          </div> */}

                          {/* <div className="dropdown dost-buttons-wrapper">
                              <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={dostsIcon} alt="dostsIcon" />
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li onClick={() => MoveNext(item)}> */}
                          {/* <a className="dropdown-item" href="#"> */}
                          {/* <Link to={'/leader'} className="dropdown-item">
                                    <img src={viewDetailsIcon} alt="viewDetailsIcon" />
                                    View Details
                                  </Link> */}
                          {/* </a> */}
                          {/* </li> */}
                          {/* <li>
                                  <a className="dropdown-item" href="#">
                                    <img src={editIcon} alt="editIcon" />
                                    Edit</a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <img src={deleteIcon} alt="deleteIcon" />
                                    Delete</a>
                                </li>
                              </ul>
                            </div>
                          </div> */}
                          {/* <div className="acctexts">
                            <h6 className="textleft">Owner’s %</h6>
                            <div class="fytgyugy">
                              <input
                                type="checkbox"
                                class="checkbox"
                                id={item?._id}
                                defaultChecked={item?.isActive}
                                onChange={() => publishUnpublishCode(item?._id)}
                              />
                              <label class="switch" for={item?._id}>
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div> */}
                          <div className="acctexts">
                            <h6 className="textleft">Owner’s %</h6>
                            <p className="textright" style={{ fontWeight: '700' }}>
                              {/* {item?.totalUsed > 0 ? (
                                <span className="dark">
                                  {item?.totalUsed} ({item?.totalEth?.toFixed(4)}{" "}
                                  ETH + {item?.totalUsdt?.toFixed(2)} USDT)
                                </span>
                              ) : (
                                0
                              )} */}
                              {item?.ownerPercentage / 10}%
                            </p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">User’s %</h6>
                            <p className="textright" style={{ fontWeight: '700' }}>
                              {/* {item?.totalUsed > 0 ? (
                                <span className="dark">
                                  {item?.totalUsed} ({item?.totalEth?.toFixed(4)}{" "}
                                  ETH + {item?.totalUsdt?.toFixed(2)} USDT)
                                </span>
                              ) : (
                                0
                              )} */}
                              {item?.userPercentage / 10}%
                            </p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Total Purchases</h6>
                            <p className="textright">
                              {item?.totalUsed > 0 ? (
                                <>
                                  <span className="dark ">
                                    {item?.totalUsed}
                                  </span>
                                  ({item?.totalEth?.toFixed(4)}{" "}
                                  ETH + {item?.totalUsdt?.toFixed(2)} USDT)
                                </>
                              ) : (
                                0
                              )}
                              {/* 254 (1.5K ETH + 8K USDT) */}
                            </p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Active</h6>
                            <p className="textright">
                              <div class="fytgyugy">
                                <input
                                  type="checkbox"
                                  class="checkbox"
                                  id={item?._id}
                                  defaultChecked={item?.isActive}
                                  onChange={() => publishUnpublishCode(item?._id)}
                                />
                                <label class="switch" for={item?._id}>
                                  <span class="slider"></span>
                                </label>
                              </div>
                            </p>
                          </div>
                          <div className="acctexts">
                            <button className="btn-view-detail" onClick={() => MoveNext(item)}>
                              View Details
                            </button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                );
              })
            ) : loader ? (
              <tr>
                <td colSpan="8" className="text-center">
                  <div className="text-center">
                    {<Spinner animation="border" variant="info" />}
                    {/* <h4>No Categories</h4> */}
                  </div>
                </td>
              </tr>
            ) : (
              <p>No Records</p>
            )}
          </div>
          <div className="footer-content">
            <div className="left-f">
              {count > 0 && count <= limit ? (
                <>
                  <h6>
                    SHOWING 1-{count} OF {count}
                  </h6>
                </>
              ) : count > limit ? (
                <>
                  <h6>
                    SHOWING {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                    {limit * offset > count ? count : limit * offset} OF {count}
                  </h6>
                </>
              ) : (
                <>
                  <h6>SHOWING 0 OF 0</h6>
                </>
              )}
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  {/* <Pagination>
                  {startPage > 1 && (
                    <>
                      <Pagination.First onClick={() => handlePageClick(1)} />
                      <Pagination.Prev
                        onClick={() => handlePageClick(currentPage - 1)}
                      />
                    </>
                  )}

                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, i) => startPage + i
                  ).map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => handlePageClick(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  {endPage < totalPages && (
                    <>
                      <Pagination.Next
                        onClick={() => handlePageClick(currentPage + 1)}
                      />
                      <Pagination.Last
                        onClick={() => handlePageClick(totalPages)}
                      />
                    </>
                  )}
                </Pagination> */}
                  <Stack spacing={2}>
                    <Pagination
                      count={page}
                      page={offset}
                      onChange={(e, p) => setOffset(p)}
                    // color="secondary"
                    />
                  </Stack>

                  {/* <li class="page-item">
                  <a class="page-link" href="#">
                    <img
                      src="\Assets\tomitoken-assets\arrow1.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link active" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    4
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    5
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <img
                      src="\Assets\tomitoken-assets\arrow2.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                </li> */}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Datatable;
