import React, { useState, useEffect } from "react";
import "./transactionsTable.scss";
import { Accordion } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "@mui/material/Pagination";
// import Pagination from "react-bootstrap/Pagination";
import Stack from "@mui/material/Stack";
import moment from "moment";
import dostsIcon from "../../Assests/dostsIcon.svg";
import deleteIcon from "../../Assests/deleteIcon.svg";
import editIcon from "../../Assests/editIcon.svg";
import viewDetailsIcon from "../../Assests/viewDetailsIcon.svg";
import Navbar from "../Navbar/Navbar";
import ReactPaginate from "react-paginate";
import externalIcon from "../../Assests/externalIcon.svg";

const TransactionsTable = ({ setCodeDataCall, codeDataCall }) => {
  const history = useHistory();
  const val = localStorage.getItem("accessToken");
  const [loader, setLoader] = useState(false);
  const [accessCodesData, setAccessCodesData] = useState([]);
  const [orderField, setOrderField] = useState("createdAt");
  const [orderDirection, setOrderDirection] = useState("-1");
  const [usdPrice, setUsdPrice] = useState(0);
  const [pageCount, setPageCount] = useState([]);
  // console.log("pagecount",pageCount)
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [names, setNames] = useState([]);

  const [totalPages, setTotalPages] = useState(1);

  const maxVisiblePages = 5; // Maximum number of visible page numbers
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the start and end indices for visible page numbers

  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

  // Function to handle page click

  useEffect(() => {
    if (val && val !== "" && val !== undefined) {
      setCodeDataCall(() => accessCodesList);
      accessCodesList();
    } else {
      history.push("/");
    }
  }, [offset, orderField, orderDirection]);

  useEffect(() => {
    axios
      .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
      .then((res) => {
        setUsdPrice(res?.data?.USD);
        // setEthUsd(res.data);
      });
  }, []);

  const accessCodesList = async () => {
    setLoader(true);
    setAccessCodesData([]);
    var config = {
      method: "get",
      url: `${Api_Url}/access-codes?limit=${10}&offset=${offset}&orderField=${orderField}&orderDirection=${orderDirection}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response?.data?.data;
        setTotalPages(resData?.pages);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        // setPage(resData?.pages);
        setAccessCodesData(resData?.accessCodes);
        setLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    displayData();
    getTotalList();
  }, []);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // let a = JSON.stringify(inputs?.auctionCounted);
    let skip = "";
    if (e.selected > 0) {
      skip = JSON.stringify(e.selected * 10);
      setPage(selectedPage);
    } else {
      skip = JSON.stringify(0);
      setPage(0);
    }
    const userOb = JSON.parse(localStorage.getItem("user"));
    var data = null;
    if (userOb?.role === "user" && userOb?.limitedAccessCodes?.length > 0) {
      let cod = [];
      for (let i of userOb?.limitedAccessCodes) {
        cod?.push(i?.code)
      }
      data = JSON.stringify({
        query: `query MyQuery {
            investments(
              where: {code_code_in: ${JSON.stringify(cod)}}
              orderDirection: desc
              first: 10
              skip: ${skip}
              orderBy: PurchaseTime
            ) {
                totalTomi
                token
                ownerFunds
                isClaimed
                id
                currentIndex
                code_userPercentage
                code_ownerPercentage
                code_ownerAddress
                code_code
                ReleaseTime
                PurchaseTime
                By
                Bonus
                AmountTomi
                AmountSpent
                trxHash
            }
          }`,
      });
    } else {
      data = JSON.stringify({
        query: `query MyQuery {
            investments(
              orderDirection: desc
              first: 10
              skip: ${skip}
              orderBy: PurchaseTime
            ) {
                totalTomi
                token
                ownerFunds
                isClaimed
                id
                currentIndex
                code_userPercentage
                code_ownerPercentage
                code_ownerAddress
                code_code
                ReleaseTime
                PurchaseTime
                By
                Bonus
                AmountTomi
                AmountSpent
                trxHash
            }
          }`,
      });
    }
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/41280/tomi-china/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setData(response?.data?.data?.investments);
        let dumArr = [];
        for (let i of response?.data?.data?.investments) {
          if (
            i?.code_code !== "4402327315" &&
            i?.code_code !== "0730633351" &&
            i?.code_ownerPercentage !== "1000"
          ) {
            dumArr.push(i?.code_code);
          }
        }
        if (dumArr?.length > 0) {
          const val = localStorage.getItem("accessToken");
          const url = `${Api_Url}/access-codes/names?accessCodes[]=${dumArr.join(
            "&accessCodes[]="
          )}`;
          var config = {
            method: "get",
            url: url,
            headers: {
              Authorization: "Bearer " + val,
            },
          };

          axios(config)
            .then(function (response) {
              setNames(response?.data?.data);
            })
            .catch(function (error) { });
        }
      })
      .catch(function (error) { });
  };

  const displayData = async () => {
    const userOb = JSON.parse(localStorage.getItem("user"));
    var data = null;
    if (userOb?.role === "user" && userOb?.limitedAccessCodes?.length > 0) {
      let cod = [];
      for (let i of userOb?.limitedAccessCodes) {
        cod?.push(i?.code)
      }
      data = JSON.stringify({
        query: `query MyQuery {
            investments(
              where: {code_code_in: ${JSON.stringify(cod)}}
              orderDirection: desc
              first: 10
              orderBy: PurchaseTime
            ) {
                totalTomi
                token
                ownerFunds
                isClaimed
                id
                currentIndex
                code_userPercentage
                code_ownerPercentage
                code_ownerAddress
                code_code
                ReleaseTime
                PurchaseTime
                By
                Bonus
                AmountTomi
                AmountSpent
                trxHash
            }
          }`,
      });
    } else {
      data = JSON.stringify({
        query: `query MyQuery {
            investments(
              orderDirection: desc
              first: 10
              orderBy: PurchaseTime
            ) {
                totalTomi
                token
                ownerFunds
                isClaimed
                id
                currentIndex
                code_userPercentage
                code_ownerPercentage
                code_ownerAddress
                code_code
                ReleaseTime
                PurchaseTime
                By
                Bonus
                AmountTomi
                AmountSpent
                trxHash
            }
          }`,
      });
    }
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/41280/tomi-china/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        setData(response?.data?.data?.investments);
        let dumArr = [];
        for (let i of response?.data?.data?.investments) {
          if (
            i?.code_code !== "4402327315" &&
            i?.code_code !== "0730633351" &&
            i?.code_ownerPercentage !== "1000"
          ) {
            dumArr.push(i?.code_code);
          }
        }
        if (dumArr?.length > 0) {
          const val = localStorage.getItem("accessToken");
          const url = `${Api_Url}/access-codes/names?accessCodes[]=${dumArr.join(
            "&accessCodes[]="
          )}`;
          var config = {
            method: "get",
            url: url,
            headers: {
              Authorization: "Bearer " + val,
            },
          };

          axios(config)
            .then(function (response) {
              setNames(response?.data?.data);
            })
            .catch(function (error) { });
        }
      })
      .catch(function (error) {
        console.log(error, "error");
        // setstackingTime('')
      });
  };

  const getTotalList = async () => {
    const userOb = JSON.parse(localStorage.getItem("user"));
    var data = null;
    if (userOb?.role === "user" && userOb?.limitedAccessCodes?.length > 0) {
      let cod = [];
      for (let i of userOb?.limitedAccessCodes) {
        cod?.push(i?.code)
      }
      data = JSON.stringify({
        query: `query MyQuery {
            investments(
              where: {code_code_in: ${JSON.stringify(cod)}}
              orderDirection: desc
              orderBy: PurchaseTime
            ) {
                totalTomi
                token
                ownerFunds
                isClaimed
                id
                currentIndex
                code_userPercentage
                code_ownerPercentage
                code_ownerAddress
                code_code
                ReleaseTime
                PurchaseTime
                By
                Bonus
                AmountTomi
                AmountSpent
                trxHash
            }
          }`,
      });
    } else {
      data = JSON.stringify({
        query: `query MyQuery {
                investments(
                  orderDirection: desc
                  orderBy: PurchaseTime
                ) {
                    totalTomi
                    token
                    ownerFunds
                    isClaimed
                    id
                    currentIndex
                    code_userPercentage
                    code_ownerPercentage
                    code_ownerAddress
                    code_code
                    ReleaseTime
                    PurchaseTime
                    By
                    Bonus
                    AmountTomi
                    AmountSpent
                }
              }`,
        variables: {},
      });
    }
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/41280/tomi-china/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        setPageCount(response?.data?.data?.investments?.length / 10);
        // let amountT = 0;
        // let percUs = 0;
        // let percEth = 0;
        // let amoUs = 0;
        // let amoEth = 0;
        // for (let i of response?.data?.data?.investments) {
        //   let amt = (parseInt(i?.AmountTomi) / 1000000000000000000).toFixed(4)
        //   amountT = amountT + parseFloat(amt);
        //   if (i?.token === "ETH") {
        //     percEth = (((parseInt(i?.AmountSpent) / 1000000000000000000) * (parseInt(i?.code_ownerPercentage)/10)) / 100) + percEth;
        //     amoEth = (parseInt(i?.AmountSpent) / 1000000000000000000) + amoEth;
        //   } else {
        //     percUs = (((parseInt(i?.AmountSpent) / 1000000) * (parseInt(i?.code_ownerPercentage)/10)) / 100) + percUs;
        //     amoUs = (parseInt(i?.AmountSpent) / 1000000) + amoUs;
        //   }
        // }
        // setPurchases({
        //   eth: amoEth,
        //   us: amoUs
        // })
        // setEarnings({
        //   eth: percEth,
        //   us: percUs
        // })
        // setTotalT(amountT)
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  };

  const ChildList = ({ item, StackIntialDate }) => {
    const GetName = () => {
      let dumObj = names?.find((i) => {
        return i.code === item?.code_code;
      });
      if (dumObj?.name) {
        return dumObj?.name;
      } else {
        return "no name";
      }
    };

    return (
      <>
        {item?.code_code !== "4402327315" &&
          item?.code_code !== "0730633351" &&
          item?.code_ownerPercentage !== "1000" && (
            <tr>
              <td>
                <span className="dark name-itemmm">
                  <div className="d-flex justify-content-left">
                    {/* {item?.name} */}
                    {GetName()}
                  </div>
                  <div className="d-flex justify-content-left normal-weightttt">
                    {item?.code_code}
                  </div>
                </span>
              </td>
              <td>
                {/* {item?.name} */}
                {/* {item?.ownerAddress} */}
                {/* <img src={externalIcon} alt="externalIcon" /> */}

                <div className="forflex">
                  <p className="forflexpara">
                    {item?.trxHash?.slice(0, 11)}...
                    {item?.trxHash?.slice(
                      item?.trxHash?.length - 3,
                      item?.trxHash?.length
                    )}
                  </p>

                  <a
                    href={"https://etherscan.io/tx/" + item?.trxHash}
                    target="_blank"
                  >
                    <img
                      src={externalIcon}
                      style={{ cursor: "pointer" }}
                      alt="forfleximg"
                      className="forfleximg"
                    />
                  </a>
                </div>
              </td>
              <td className="wallet-address-td">
                <div className="d-flex align-items-center" c>
                  <p>{item?.By}</p>
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(item?.By);
                    }}
                    src="\Assets\tomitoken-assets\copyy.svg"
                    alt="img"
                    className="me-2"
                  />
                </div>
              </td>
              <td>{(parseInt(item?.code_ownerPercentage) / 10).toFixed(2)}%</td>
              <td>{(parseInt(item?.code_userPercentage) / 10).toFixed(2)}%</td>
              <td>
                <div className="d-flex justify-content-start">
                  {/* {item?.token === "ETH" ? ( */}
                  <>
                    {(
                      parseInt(item?.AmountTomi) / 1000000000000000000
                    )?.toFixed(4) +
                      " " +
                      "tomi"}
                  </>
                  {/* ) : (
                    <>
                      {(parseInt(item?.AmountSpent) / 1000000)?.toFixed(2) +
                        " " +
                        item?.token}
                    </>
                  )} */}
                </div>
              </td>
              <td>
                <div className="d-flex justify-content-start">
                  {item?.token === "ETH" ? (
                    <>
                      {(
                        parseInt(item?.AmountSpent) / 1000000000000000000
                      )?.toFixed(4) +
                        " " +
                        item?.token}
                    </>
                  ) : (
                    <>
                      {(parseInt(item?.AmountSpent) / 1000000)?.toFixed(2) +
                        " " +
                        item?.token}
                    </>
                  )}
                </div>
              </td>
              <td>{StackIntialDate}</td>
            </tr>
          )}
      </>
    );
  };

  const ChildList2 = ({ item, StackIntialDate, index }) => {

    const GetName = () => {
      let dumObj = names?.find((i) => {
        return i.code === item?.code_code;
      });
      if (dumObj?.name) {
        return dumObj?.name;
      } else {
        return "no name";
      }
    };

    return (
      <>
        {item?.code_code !== "4402327315" &&
          item?.code_code !== "0730633351" &&
          item?.code_ownerPercentage !== "1000" && (
            <Accordion defaultActiveKey={-1}>
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                  <div className="accheadermain">
                    <p className="acctext">
                      {/* {item?.code} */}
                      <span className="dark name-itemmm">
                        <div className="d-flex justify-content-left" style={{ fontWeight: 700, marginBottom: '4px' }}>
                          {GetName()}
                        </div>
                        <div className="d-flex justify-content-left normal-weightttt">
                          {item?.code_code}
                        </div>
                      </span>
                    </p>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="acctexts">
                    <h6 className="textleft">Wallet Address</h6>
                    <p className="textright">
                      {item?.By?.slice(0, 11) + "..."}
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(item?.By);
                        }}
                        src="\Assets\tomitoken-assets\copyy.svg"
                        alt="img"
                      // className="me-2"
                      />
                    </p>
                  </div>
                  <div className="acctexts">
                    <h6 className="textleft">Hash</h6>
                    <div className="forflex d-flex align-items-center">
                      <p className="textright ">
                        {item?.trxHash?.slice(0, 11)}...
                        {item?.trxHash?.slice(
                          item?.trxHash?.length - 3,
                          item?.trxHash?.length
                        )}
                      </p>

                      <a
                        href={"https://etherscan.io/tx/" + item?.trxHash}
                        target="_blank"
                        className="hash-img-mobile"
                      >
                        <img
                          src={externalIcon}
                          style={{ cursor: "pointer" }}
                          alt="forfleximg"
                          className="forfleximg"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="acctexts">
                    <h6 className="textleft">Owner's %</h6>
                    <p className="textright">
                      ({parseInt(item?.code_ownerPercentage / 10).toFixed(2)})
                    </p>
                  </div>

                  <div className="acctexts">
                    <h6 className="textleft">User's %</h6>
                    <p className="textright">
                      ({(item?.code_userPercentage / 10).toFixed(2)})
                    </p>
                  </div>
                  <div className="acctexts">
                    <h6 className="textleft">tomi Purchased</h6>
                    {item?.token === "ETH" ? (
                      <p className="textright">
                        {(
                          ((parseInt(item?.AmountSpent) / 1000000000000000000) *
                            (parseInt(item?.code_ownerPercentage) / 10)) /
                          100
                        )?.toFixed(4)}{" "}
                        {item?.token}
                      </p>
                    ) : (
                      <p className="textright">
                        {(
                          ((parseInt(item?.AmountSpent) / 1000000) *
                            (parseInt(item?.code_ownerPercentage) / 10)) /
                          100
                        )?.toFixed(2)}{" "}
                        {item?.token}
                      </p>
                    )}
                  </div>
                  <div className="acctexts">
                    <h6 className="textleft">Date</h6>
                    <div class="fytgyugy w-auto textright">{StackIntialDate}</div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
      </>
    );
  };

  return (
    <>
      <section className="datatable leader-home-datetable-tableeee">
        <div class="table-responsivecolorbg">
          <div className="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div
                      className="parent-tag"
                      onClick={() => {
                        setOrderField("code");
                        setOrderDirection(() => {
                          if (orderDirection == -1) return 1;
                          else return -1;
                        });
                      }}
                    >
                      Name/Access Code
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag"
                      onClick={() => {
                        setOrderField("ownerAddress");
                        setOrderDirection(() => {
                          if (orderDirection == -1) return 1;
                          else return -1;
                        });
                      }}
                    >
                      Hash
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag"
                      onClick={() => {
                        setOrderField("ownerPercentage");
                        setOrderDirection(() => {
                          if (orderDirection == -1) return 1;
                          else return -1;
                        });
                      }}
                    >
                      Wallet Address
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag"
                      onClick={() => {
                        setOrderField("userPercentage");
                        setOrderDirection(() => {
                          if (orderDirection == -1) return 1;
                          else return -1;
                        });
                      }}
                    >
                      Owner%
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag"
                      onClick={() => {
                        setOrderField("name");
                        setOrderDirection(() => {
                          if (orderDirection == -1) return 1;
                          else return -1;
                        });
                      }}
                    >
                      Users %
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag sdkfnklasf">
                      tomi Purchased
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag sdkfnklasf">
                      Amount Purchased
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag"
                      onClick={() => {
                        setOrderField("isActive");
                        setOrderDirection(() => {
                          if (orderDirection == -1) return 1;
                          else return -1;
                        });
                      }}
                    >
                      Date
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => {
                    let a = parseInt(item?.PurchaseTime);
                    let cc = new Date(a);
                    const StackIntialDate = moment(cc * 1000).format(
                      "DD-MM-YYYY H:mm"
                    );
                    return (
                      <>
                        <ChildList
                          StackIntialDate={StackIntialDate}
                          item={item}
                        />
                      </>
                    );
                  })
                ) : loader ? (
                  <tr>
                    <td colSpan="8" className="text-center">
                      <div className="text-center">
                        {<Spinner animation="border" variant="info" />}
                        {/* <h4>No Categories</h4> */}
                      </div>
                    </td>
                  </tr>
                ) : (
                  <p>No Records</p>
                )}
              </tbody>
            </table>
          </div>
          <div className="accmblview d-none">
            <h6 className="accmblviewhead">
              Name/Access Code{" "}
              <div className="arrowsacc">
                <img
                  src="\Assets\tomitoken-assets\tableup.svg"
                  alt="tableupr"
                  className="tableup"
                />
                <img
                  src="\Assets\tomitoken-assets\tabledown.svg"
                  alt="tabledown"
                  className="tabledown"
                />
              </div>
            </h6>

            {data.length > 0 ? (
              data.map((item, index) => {
                let a = parseInt(item?.PurchaseTime);
                let cc = new Date(a);
                const StackIntialDate = moment(cc * 1000).format(
                  "DD-MM-YYYY H:mm"
                );
                return (
                  <>
                    <ChildList2
                      StackIntialDate={StackIntialDate}
                      item={item}
                      index={index}
                    />
                  </>
                );
              })
            ) : loader ? (
              <tr>
                <td colSpan="8" className="text-center">
                  <div className="text-center">
                    {<Spinner animation="border" variant="info" />}
                    {/* <h4>No Categories</h4> */}
                  </div>
                </td>
              </tr>
            ) : (
              <p>No Records</p>
            )}
          </div>
          <div className="footer-content">
            <div className="left-f">
              {/* {count > 0 && count <= limit ? (
                                <>
                                    <h6>
                                        SHOWING 1-{count} OF {count}
                                    </h6>
                                </>
                            ) : count > limit ? (
                                <>
                                    <h6>
                                        SHOWING {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                                        {limit * offset > count ? count : limit * offset} OF {count}
                                    </h6>
                                </>
                            ) : (
                                <>
                                    <h6>SHOWING 0 OF 0</h6>
                                </>
                            )} */}
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="text-center">
                  <ReactPaginate
                    previousLabel={
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                        <path d="M10 13L5 8L10 3" stroke="#A0A1A1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    }
                    nextLabel={
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                        <path d="M5 13L10 8L5 3" stroke="#FF0083" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    }
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={page}
                  ></ReactPaginate>
                  {/* <Pagination>
                  {startPage > 1 && (
                    <>
                      <Pagination.First onClick={() => handlePageClick(1)} />
                      <Pagination.Prev
                        onClick={() => handlePageClick(currentPage - 1)}
                      />
                    </>
                  )}

                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, i) => startPage + i
                  ).map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => handlePageClick(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  {endPage < totalPages && (
                    <>
                      <Pagination.Next
                        onClick={() => handlePageClick(currentPage + 1)}
                      />
                      <Pagination.Last
                        onClick={() => handlePageClick(totalPages)}
                      />
                    </>
                  )}
                </Pagination> */}
                  {/* <Stack spacing={2}>
                                        <Pagination
                                            count={page}
                                            page={offset}
                                            onChange={(e, p) => setOffset(p)}
                                        // color="secondary"
                                        />
                                    </Stack> */}

                  {/* <li class="page-item">
                  <a class="page-link" href="#">
                    <img
                      src="\Assets\tomitoken-assets\arrow1.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link active" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    4
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    5
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <img
                      src="\Assets\tomitoken-assets\arrow2.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                </li> */}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TransactionsTable;
