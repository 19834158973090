import React, { useState, useEffect } from "react";
import "./DatatableNew.scss";
import { Accordion } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "@mui/material/Pagination";
// import Pagination from "react-bootstrap/Pagination";
import Stack from "@mui/material/Stack";
import moment from "moment";
import dostsIcon from "../../Assests/dostsIcon.svg";
import deleteIcon from "../../Assests/deleteIcon.svg";
import editIcon from "../../Assests/editIcon.svg";
import viewDetailsIcon from "../../Assests/viewDetailsIcon.svg";
import Navbar from "../Navbar/Navbar";

const DatatableNew = ({ setCodeDataCall, codeDataCall }) => {
    const history = useHistory();
    const val = localStorage.getItem("accessToken");
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [accessCodesData, setAccessCodesData] = useState([]);
    const [orderField, setOrderField] = useState("createdAt");
    const [orderDirection, setOrderDirection] = useState("-1");
    const [usdPrice, setUsdPrice] = useState(0);
    const [pageCount, setPageCount] = useState([]);
    const [offset, setOffset] = useState(1);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [tomiPrice, setchange24hr1] = useState(0);
    const [purchases, setPurchases] = useState(null);
    const [limit, setLimit] = useState(10);
    const [names, setNames] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [user, setUser] = useState(null);

    const maxVisiblePages = 5; // Maximum number of visible page numbers
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        get_ApiKeys();
        axios
            .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
            .then((res) => {
                setUsdPrice(res?.data?.USD);
                // setEthUsd(res.data);
            });
    }, []);

    const get_ApiKeys = async () => {
        try {
            const response = await axios({
                method: "get",
                url: "https://tomipay-staging.tomi.com/users/private-keys",
            });
            GetCoinsList(response?.data?.keys?.AddCustomToken);
            // console.log(
            //   response?.data?.keys?.AddCustomToken,
            //   "response?.data?.keys?.AddCustomToken"
            // );
            // setApiKey(response?.data?.keys?.AddCustomToken);
        } catch (error) {
            // console.log("Error in get_ApiKeys:", await error.response);
            throw error;
        }
    };

    const GetCoinsList = async (e) => {
        let coingeckoList = await axios.get(
            `https://pro-api.coingecko.com/api/v3/coins/list?include_platform=true&x_cg_pro_api_key=${e}`
        );
        let dumArr = coingeckoList.data.filter((i) => {
            // console.log(i);
            if (i?.id === "tominet") {
                return i;
            }
        });
        // console.log(dumArr[0].id, "dumArr");
        coindata(e, dumArr[0].id);
    };

    const coindata = async (e, d) => {
        let res = await axios.get(
            `https://pro-api.coingecko.com/api/v3/coins/${d}?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false&x_cg_pro_api_key=${e}`
        );
        // console.log(res, "rsdsdes");
        // dta(res)
        let change24hr = res?.data.market_data?.price_change_percentage_24h;
        let curentprice = res.data?.market_data?.current_price?.usd;
        let imageurl = res.data?.image.small;
        setchange24hr1(change24hr);
        // console.log(change24hr, curentprice, imageurl, "fdgdsfgdssf");
    };
    // Calculate the start and end indices for visible page numbers

    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Function to handle page click
    const handlePageClick = (page) => {
        setCurrentPage(page);
        // You can perform additional actions here, such as fetching data for the selected page.
    };

    useEffect(() => {
        if (usdPrice) {
            if (val && val !== "" && val !== undefined) {
                setCodeDataCall(() => accessCodesList);
                accessCodesList();
            } else {
                history.push("/");
            }
        }
    }, [offset, orderField, orderDirection, usdPrice]);

    const accessCodesList = async () => {
        setLoader(true);
        setAccessCodesData([]);
        var config = {
            method: "get",
            url: `${Api_Url}/access-codes?limit=${limit}&offset=${offset}&orderField=${orderField}&orderDirection=${orderDirection}`,
            headers: {
                Authorization: "Bearer " + val,
            },
        };

        axios(config)
            .then(function (response) {
                const resData = response?.data?.data;
                setTotalPages(resData?.pages);
                let arr = Array.from(Array(parseInt(resData.pages)).keys());
                setCount(resData?.count);
                // setPage(resData?.pages);
                setPageCount(arr);
                // setAccessCodesData(resData?.accessCodes);
                let sortedData = resData?.accessCodes.sort((a, b) => {
                    return (
                        a.totalEth * usdPrice +
                        a.totalUsdt -
                        (b.totalEth * usdPrice + b.totalUsdt)
                    );
                });
                // let sortedData = resData?.accessCodes.sort((a, b) => ((a.totalEth * usdPrice) + a.totalUsdt) - ((b.totalEth * usdPrice) + b.totalUsdt));
                // let sortedData = resData?.accessCodes.sort((a, b) => {
                //     if (a.totalUsdt === 0 && b.totalUsdt === 0) {
                //         return a.totalEth - b.totalEth; // Sort based on totalEth if both totalUsdt are 0
                //     }

                //     if (a.totalUsdt === 0) {
                //         return 1; // Place the item with totalUsdt 0 at the end
                //     }

                //     if (b.totalUsdt === 0) {
                //         return -1; // Place the item with totalUsdt 0 at the end
                //     }

                //     // Sort based on the calculated value when both totalUsdt are non-zero
                //     return ((a.totalEth * usdPrice) + a.totalUsdt) - ((b.totalEth * usdPrice) + b.totalUsdt);
                // });
                // sortedData = sortedData?.reverse();
                // setAccessCodesData(sortedData?.slice(0, 10));
                // console.log("sortedData", sortedData?.reverse())
                setAccessCodesData(sortedData?.reverse());
                // setAccessCodesData(resData?.accessCodes)
                setLoader(false);
            })
            .catch(function (error) {
                if (error?.response?.status == 401) {
                    localStorage.removeItem("accessToken");
                    history.push("/");
                }
                setLoader(false);
            });
    };

    const publishUnpublishCode = async (id) => {
        // setLoader(true);
        const config = {
            method: "patch",
            url: Api_Url + "/access-codes/" + id,
            headers: {
                Authorization: "Bearer " + val,
            },
        };

        await axios(config)
            .then((res) => {
                // setLoader(false);
            })
            .catch((err) => {
                if (err?.response?.status == 501) {
                    localStorage.removeItem("accessToken");
                    history.push("/");
                } else if (err?.response?.status == 401) {
                    localStorage.removeItem("accessToken");
                    history.push("/");
                } else {
                    console.log("error meessage: ", err?.response?.data?.message);
                }
                setLoader(false);
            });
    };

    const MoveNext = (item) => {
        localStorage.setItem("current", JSON.stringify(item));
        history.push("/leader?owner=" + item?.ownerAddress);
    };

    useEffect(() => {
        displayData();
    }, []);

    const displayData = async () => {
        const userOb = JSON.parse(localStorage.getItem("user"));
        setUser(userOb)
        var data = null;
        if (userOb?.role === "user" && userOb?.limitedAccessCodes?.length > 0) {
            let cod = [];
            for (let i of userOb?.limitedAccessCodes) {
                cod?.push(i?.code)
            }
            var data = JSON.stringify({
                query: `query MyQuery {
                investments(
                  where: {code_code_in: ${JSON.stringify(cod)}}
                  orderDirection: desc
                  first: 14
                  orderBy: PurchaseTime
                ) {
                    totalTomi
                    token
                    ownerFunds
                    isClaimed
                    id
                    currentIndex
                    code_userPercentage
                    code_ownerPercentage
                    code_ownerAddress
                    code_code
                    ReleaseTime
                    PurchaseTime
                    By
                    Bonus
                    trxHash
                    AmountTomi
                    AmountSpent
                }
              }`,
                variables: {},
            });
        } else {
            var data = JSON.stringify({
                query: `query MyQuery {
                investments(
                  orderDirection: desc
                  first: 14
                  orderBy: PurchaseTime
                ) {
                    totalTomi
                    token
                    ownerFunds
                    isClaimed
                    id
                    currentIndex
                    code_userPercentage
                    code_ownerPercentage
                    code_ownerAddress
                    code_code
                    ReleaseTime
                    PurchaseTime
                    By
                    Bonus
                    trxHash
                    AmountTomi
                    AmountSpent
                }
              }`,
                variables: {},
            });
        }
        var config = {
            method: "post",
            url: "https://api.studio.thegraph.com/query/41280/tomi-china/version/latest",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        await axios(config)
            .then(async function (response) {
                setData(response?.data?.data?.investments);
                let dumArr = [];
                for (let i of response?.data?.data?.investments) {
                    if (i?.code_code !== "4402327315" &&
                        i?.code_code !== "0730633351" && i?.code_ownerPercentage !== "1000") {
                        dumArr.push(i?.code_code)
                    }
                }
                if (dumArr?.length > 0) {
                    const val = localStorage.getItem("accessToken");
                    const url = `${Api_Url}/access-codes/names?accessCodes[]=${dumArr.join("&accessCodes[]=")}`;
                    var config = {
                        method: "get",
                        url: url,
                        headers: {
                            Authorization: "Bearer " + val,
                        },
                    };

                    axios(config)
                        .then(function (response) {
                            setNames(response?.data?.data)
                        })
                        .catch(function (error) {
                        });
                }
            })
            .catch(function (error) {
                console.log(error, "error");
                // setstackingTime('')
            });
    };

    const ChildList = ({ item, StackIntialDate, names }) => {
        const [spent, setSpent] = useState(0);
        const [totalTomi, setTotalTomi] = useState(0);
        const [revenue, setRevenue] = useState(0);
        const [amtTomi, setAmtTomi] = useState(0);

        useEffect(() => {
            if (tomiPrice && usdPrice) {
                let spent0 = 0;
                let rev = 0;
                let ownPer = 0;
                let userPer = 0;
                if (item?.token === "ETH") {
                    setSpent(parseInt(item?.AmountSpent) / 1000000000000000000);
                    spent0 =
                        (parseInt(item?.AmountSpent) / 1000000000000000000) * usdPrice;
                    ownPer = (spent0 * (parseInt(item?.code_ownerPercentage) / 10)) / 100;
                } else {
                    setSpent(parseInt(item?.AmountSpent) / 1000000);
                    spent0 = parseInt(item?.AmountSpent) / 1000000;
                    ownPer = (spent0 * (parseInt(item?.code_ownerPercentage) / 10)) / 100;
                }
                setTotalTomi(parseInt(item?.totalTomi) / 1000000000000000000);
                let amt = parseInt(item?.AmountTomi) / 1000000000000000000;
                setAmtTomi(amt);
                amt = (amt * parseInt(item?.code_userPercentage)) / 10 / 100;
                amt = amt * Math.abs(tomiPrice);
                // if (item?.token === "ETH") {
                //     ownPer = ownPer * usdPrice
                // }
                rev = spent0 - (amt + ownPer);
                setRevenue(rev);
            }
        }, [item, tomiPrice, usdPrice]);

        const GetName = () => {
            let dumObj = names?.find((i) => {
                return i.code === item?.code_code;
            })
            if (dumObj?.name) {
                return dumObj?.name;
            } else {
                return "..."
            }
        }

        return (
            <>
                {(item?.code_code !== "4402327315" &&
                    item?.code_code !== "0730633351" && item?.code_ownerPercentage !== "1000") && (
                        <tr>
                            <td>
                                {StackIntialDate} <br />
                            </td>
                            <td>{GetName()}</td>
                            <td className="wallet-address-td">
                                <div className="d-flex align-items-center">
                                    <p>
                                        {item?.By?.slice(0, 11)}...
                                        {item?.By?.slice(item?.By?.length - 3, item?.By?.length)}{" "}
                                        &nbsp;{" "}
                                    </p>
                                    <img
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            navigator.clipboard.writeText(item?.By);
                                        }}
                                        src="\Assets\tomitoken-assets\copyy.svg"
                                        alt="img"
                                        className="me-2"
                                    />
                                </div>
                            </td>
                            <td>
                                <div className="d-flex align-items-center">
                                    {item?.trxHash?.slice(0, 11)}...
                                    {item?.trxHash?.slice(
                                        item?.trxHash?.length - 3,
                                        item?.trxHash?.length
                                    )}{" "}
                                    &nbsp;
                                    <a
                                        href={"https://etherscan.io/tx/" + item?.trxHash}
                                        target="_blank"
                                    >
                                        <img
                                            src="\Assets\tomitoken-assets\export.svg"
                                            alt="img"
                                            className="me-2"
                                        />
                                    </a>
                                </div>
                            </td>
                            <td>{spent?.toFixed(2) + " " + item?.token}</td>
                            <td>
                                <p className="dark">
                                    {(parseInt(item?.code_ownerPercentage) / 10).toFixed(2)}%
                                </p>
                                {/* <p className="lighttext">381.7 {item?.token}</p> */}
                                <p className="lighttext">
                                    {(
                                        (spent * (parseInt(item?.code_ownerPercentage) / 10)) /
                                        100
                                    )?.toFixed(2)}{" "}
                                    {item?.token}
                                </p>
                            </td>
                            <td>
                                <p className="dark">
                                    {(parseInt(item?.code_userPercentage) / 10).toFixed(2)}%
                                </p>
                                <p className="lighttext">
                                    {(
                                        (amtTomi * parseInt(item?.code_userPercentage)) /
                                        10 /
                                        100
                                    )?.toFixed(2)}{" "}
                                    TOMI
                                </p>
                            </td>
                            {user?.role === "admin" && <td>{revenue?.toFixed(2)} USD</td>}
                            <td>{totalTomi?.toFixed(2)} TOMI</td>
                        </tr>
                    )}
            </>
        );
    };

    const ChildList2 = ({ item, StackIntialDate, index }) => {
        const [spent, setSpent] = useState(0);
        const [totalTomi, setTotalTomi] = useState(0);
        const [revenue, setRevenue] = useState(0);
        const [amtTomi, setAmtTomi] = useState(0);

        useEffect(() => {
            if (tomiPrice && usdPrice) {
                let spent0 = 0;
                let rev = 0;
                let ownPer = 0;
                let userPer = 0;
                if (item?.token === "ETH") {
                    setSpent(parseInt(item?.AmountSpent) / 1000000000000000000);
                    spent0 =
                        (parseInt(item?.AmountSpent) / 1000000000000000000) * usdPrice;
                    ownPer = (spent0 * (parseInt(item?.code_ownerPercentage) / 10)) / 100;
                } else {
                    setSpent(parseInt(item?.AmountSpent) / 1000000);
                    spent0 = parseInt(item?.AmountSpent) / 1000000;
                    ownPer = (spent0 * (parseInt(item?.code_ownerPercentage) / 10)) / 100;
                }
                setTotalTomi(parseInt(item?.totalTomi) / 1000000000000000000);
                let amt = parseInt(item?.AmountTomi) / 1000000000000000000;
                setAmtTomi(amt);
                amt = (amt * parseInt(item?.code_userPercentage)) / 10 / 100;
                amt = amt * Math.abs(tomiPrice);
                // if (item?.token === "ETH") {
                //     ownPer = ownPer * usdPrice
                // }
                rev = spent0 - (amt + ownPer);
                setRevenue(rev);
            }
        }, [item, tomiPrice, usdPrice]);

        const GetName = () => {
            let dumObj = names?.find((i) => {
                return i.code === item?.code_code;
            })
            if (dumObj?.name) {
                return dumObj?.name;
            } else {
                return "..."
            }
        }

        return (
            <>
                {(item?.code_code !== "4402327315" &&
                    item?.code_code !== "0730633351" && item?.code_ownerPercentage !== "1000") && (
                        <Accordion defaultActiveKey={-1}>
                            <Accordion.Item eventKey={index}>
                                <Accordion.Header className="paddd">
                                    <div className="accheadermain">
                                        <p className="acctexti">
                                            {/* {item?.code} */}
                                            {StackIntialDate?.slice(0, 10)}
                                        </p>
                                        <p className="acctexti">
                                            {/* {item?.code} */}
                                            {GetName()}
                                        </p>
                                        <p className="acctexti">
                                            {/* {item?.code} */}
                                            {totalTomi?.toFixed(2)} TOMI
                                        </p>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="acctexts">
                                        <h6 className="textleft">Date & Time </h6>
                                        {/* <p className="textright">{item?.ownerAddress}</p> */}
                                        <p className="textright">{StackIntialDate}</p>
                                    </div>
                                    <div className="acctexts">
                                        <h6 className="textleft">Hash</h6>
                                        <p className="textright">
                                            {/* {(item?.ownerPercentage / 10).toFixed(2)} */}
                                            {item?.trxHash?.slice(0, 11)}...
                                            {item?.trxHash?.slice(
                                                item?.trxHash?.length - 3,
                                                item?.trxHash?.length
                                            )}{" "}
                                            &nbsp;
                                            <a
                                                href={"https://etherscan.io/tx/" + item?.trxHash}
                                                target="_blank"
                                            >
                                                <img
                                                    src="\Assets\tomitoken-assets\export.svg"
                                                    alt="img"
                                                    className=""
                                                />
                                            </a>
                                            {/* 0x97E6E5C8DC...79A
                                <img src="\Assets\tomitoken-assets\export.svg" alt="img" /> */}
                                        </p>
                                    </div>
                                    <div className="acctexts">
                                        <h6 className="textleft">User’s Wallet</h6>
                                        <p className="textright">
                                            {/* ({(item?.userPercentage / 10).toFixed(2)}) */}
                                            {item?.By?.slice(0, 11)}...
                                            {item?.By?.slice(item?.By?.length - 3, item?.By?.length)}
                                            <img
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(item?.By);
                                                }}
                                                src="\Assets\tomitoken-assets\copyy.svg"
                                                alt="img"
                                            />
                                        </p>
                                    </div>

                                    <div className="acctexts">
                                        <h6 className="textleft">Amount Spent</h6>
                                        <p className="textright">
                                            {/* ({(item?.userPercentage / 10).toFixed(2)}) */}
                                            {spent?.toFixed(2)} {item?.token}
                                        </p>
                                    </div>
                                    {/* <div className="acctexts">
                          <h6 className="textleft">Created On</h6>
                          <p className="textright">
                            {" "}
                            {moment(item?.createdAt)
                              .utc()
                              .format("DD/MM/YYYY hh:mm")}{" "}
                            UTC
                          </p>
                        </div>
                        <div className="acctexts">
                          <h6 className="textleft">Start Date</h6>
                          <p className="textright">
                            {moment(item?.startDate)
                              .utc()
                              .format("DD/MM/YYYY hh:mm")}{" "}
                            UTC
                          </p>
                        </div>
                        <div className="acctexts">
                          <h6 className="textleft">End Date</h6>
                          <p className="textright">
                            {moment(item?.endDate)
                              .utc()
                              .format("DD/MM/YYYY hh:mm")}{" "}
                            UTC
                          </p>
                        </div> */}
                                    <div className="acctexts">
                                        <h6 className="textleft">Owner’s %</h6>
                                        <p className="textright">
                                            {/* {item?.totalUsed > 0 ? (
                                                                <span className="dark">
                                                                    {item?.totalUsed} ({item?.totalEth?.toFixed(4)}{" "}
                                                                    ETH + {item?.totalUsdt?.toFixed(2)} USDT)
                                                                </span>
                                                            ) : (
                                                                0
                                                            )} */}
                                            <span className="dark">
                                                {(parseInt(item?.code_ownerPercentage) / 10).toFixed(2)}%
                                            </span>{" "}
                                            -{" "}
                                            {(
                                                (spent * (parseInt(item?.code_ownerPercentage) / 10)) /
                                                100
                                            )?.toFixed(2)}{" "}
                                            {item?.token}
                                        </p>
                                    </div>
                                    <div className="acctexts">
                                        <h6 className="textleft">User’s %</h6>
                                        {/* <div class="fytgyugy">
                            <input
                              type="checkbox"
                              class="checkbox"
                              id={item?._id}
                              defaultChecked={item?.isActive}
                              onChange={() => publishUnpublishCode(item?._id)}
                            />
                            <label class="switch" for={item?._id}>
                              <span class="slider"></span>
                            </label>
                          </div> */}
                                        {/* 
                                                        <div className="dropdown dost-buttons-wrapper">
                                                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <img src={dostsIcon} alt="dostsIcon" />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li onClick={() => MoveNext(item)}>
                                                                    <a className="dropdown-item" href="#">
                                                                    <Link to={'/leader'} className="dropdown-item">
                                                                        <img src={viewDetailsIcon} alt="viewDetailsIcon" />
                                                                        View Details
                                                                    </Link>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a className="dropdown-item" href="#">
                                                                        <img src={editIcon} alt="editIcon" />
                                                                        Edit</a>
                                                                </li>
                                                                <li>
                                                                    <a className="dropdown-item" href="#">
                                                                        <img src={deleteIcon} alt="deleteIcon" />
                                                                        Delete</a>
                                                                </li>
                                                            </ul>
                                                        </div> */}
                                        <p className="textright">
                                            <span className="dark">
                                                {(parseInt(item?.code_userPercentage) / 10).toFixed(2)}%
                                            </span>{" "}
                                            -{" "}
                                            {(
                                                (amtTomi * parseInt(item?.code_userPercentage)) /
                                                10 /
                                                100
                                            )?.toFixed(2)}{" "}
                                            TOMI
                                        </p>
                                    </div>
                                    {user?.role === "admin" &&
                                        <div className="acctexts">
                                            <h6 className="textleft">Net Revenue</h6>
                                            <p className="textright">{revenue?.toFixed(2) + " USD"}</p>
                                        </div>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    )}
            </>
        );
    };

    return (
        <>
            <h3 className="new-tbl-heading">Latest Transactions</h3>
            <section className="datatable">
                <div class="table-responsivecolorbg">
                    <div className="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        <div
                                            className="parent-tag"
                                            onClick={() => {
                                                setOrderField("code");
                                                setOrderDirection(() => {
                                                    if (orderDirection == -1) return 1;
                                                    else return -1;
                                                });
                                            }}
                                        >
                                            Date & Time{" "}
                                            <div className="filter-btn">
                                                <img
                                                    src="\Assets\tomitoken-assets\tableup.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                                <img
                                                    src="\Assets\tomitoken-assets\tabledown.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            className="parent-tag"
                                            onClick={() => {
                                                setOrderField("ownerAddress");
                                                setOrderDirection(() => {
                                                    if (orderDirection == -1) return 1;
                                                    else return -1;
                                                });
                                            }}
                                        >
                                            Name
                                            <div className="filter-btn">
                                                <img
                                                    src="\Assets\tomitoken-assets\tableup.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                                <img
                                                    src="\Assets\tomitoken-assets\tabledown.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            className="parent-tag"
                                            onClick={() => {
                                                setOrderField("ownerPercentage");
                                                setOrderDirection(() => {
                                                    if (orderDirection == -1) return 1;
                                                    else return -1;
                                                });
                                            }}
                                        >
                                            User’s Wallet
                                            <div className="filter-btn">
                                                <img
                                                    src="\Assets\tomitoken-assets\tableup.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                                <img
                                                    src="\Assets\tomitoken-assets\tabledown.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            className="parent-tag"
                                            onClick={() => {
                                                setOrderField("userPercentage");
                                                setOrderDirection(() => {
                                                    if (orderDirection == -1) return 1;
                                                    else return -1;
                                                });
                                            }}
                                        >
                                            Hash
                                            <div className="filter-btn">
                                                <img
                                                    src="\Assets\tomitoken-assets\tableup.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                                <img
                                                    src="\Assets\tomitoken-assets\tabledown.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            className="parent-tag"
                                            onClick={() => {
                                                setOrderField("name");
                                                setOrderDirection(() => {
                                                    if (orderDirection == -1) return 1;
                                                    else return -1;
                                                });
                                            }}
                                        >
                                            Amount Spent
                                            <div className="filter-btn">
                                                <img
                                                    src="\Assets\tomitoken-assets\tableup.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                                <img
                                                    src="\Assets\tomitoken-assets\tabledown.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    {/* <th>
                  <div
                    className="parent-tag"
                    onClick={() => {
                      setOrderField("createdAt");
                      setOrderDirection(() => {
                        if (orderDirection == -1) return 1;
                        else return -1;
                      });
                    }}
                  >
                    Created On{" "}
                    <div className="filter-btn">
                      <img
                        src="\Assets\tomitoken-assets\tableup.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <img
                        src="\Assets\tomitoken-assets\tabledown.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="parent-tag"
                    onClick={() => {
                      setOrderField("startDate");
                      setOrderDirection(() => {
                        if (orderDirection == -1) return 1;
                        else return -1;
                      });
                    }}
                  >
                    Start Date{" "}
                    <div className="filter-btn">
                      <img
                        src="\Assets\tomitoken-assets\tableup.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <img
                        src="\Assets\tomitoken-assets\tabledown.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="parent-tag"
                    onClick={() => {
                      setOrderField("endDate");
                      setOrderDirection(() => {
                        if (orderDirection == -1) return 1;
                        else return -1;
                      });
                    }}
                  >
                    End Date{" "}
                    <div className="filter-btn">
                      <img
                        src="\Assets\tomitoken-assets\tableup.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <img
                        src="\Assets\tomitoken-assets\tabledown.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </th> */}
                                    <th>
                                        <div className="parent-tag ">
                                            Owner %
                                            <div className="filter-btn">
                                                <img
                                                    src="\Assets\tomitoken-assets\tableup.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                                <img
                                                    src="\Assets\tomitoken-assets\tabledown.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            className="parent-tag"
                                            onClick={() => {
                                                setOrderField("isActive");
                                                setOrderDirection(() => {
                                                    if (orderDirection == -1) return 1;
                                                    else return -1;
                                                });
                                            }}
                                        >
                                            Users %
                                            <div className="filter-btn">
                                                <img
                                                    src="\Assets\tomitoken-assets\tableup.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                                <img
                                                    src="\Assets\tomitoken-assets\tabledown.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    {user?.role === "admin" &&
                                        <th>
                                            <div
                                                className="parent-tag"
                                            // onClick={() => {
                                            //   setOrderField("isActive");
                                            //   setOrderDirection(() => {
                                            //     if (orderDirection == -1) return 1;
                                            //     else return -1;
                                            //   });
                                            // }}
                                            >
                                                Net Revenue
                                                <div className="filter-btn">
                                                    <img
                                                        src="\Assets\tomitoken-assets\tableup.svg"
                                                        alt="img"
                                                        className="img-fluid"
                                                    />
                                                    <img
                                                        src="\Assets\tomitoken-assets\tabledown.svg"
                                                        alt="img"
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </div>
                                        </th>
                                    }
                                    <th>
                                        <div
                                            className="parent-tag"
                                        // onClick={() => {
                                        //   setOrderField("isActive");
                                        //   setOrderDirection(() => {
                                        //     if (orderDirection == -1) return 1;
                                        //     else return -1;
                                        //   });
                                        // }}
                                        >
                                            TOMI
                                            <div className="filter-btn">
                                                <img
                                                    src="\Assets\tomitoken-assets\tableup.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                                <img
                                                    src="\Assets\tomitoken-assets\tabledown.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length > 0 ? (
                                    data.map((item, index) => {
                                        let a = parseInt(item?.PurchaseTime);
                                        let cc = new Date(a);
                                        const StackIntialDate = moment(cc * 1000).format(
                                            "DD-MM-YYYY H:mm"
                                        );
                                        return (
                                            <ChildList
                                                StackIntialDate={StackIntialDate}
                                                item={item}
                                                index={index}
                                                names={names}
                                            />
                                        );
                                    })
                                ) : loader ? (
                                    <tr>
                                        <td colSpan="8" className="text-center">
                                            <div className="text-center">
                                                {<Spinner animation="border" variant="info" />}
                                                {/* <h4>No Categories</h4> */}
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    <p>No Records</p>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="accmblview d-none">
                        <div className="accmainheader">
                            <h6 className="accmblviewheadi">Date</h6>
                            <h6 className="accmblviewheadi">Name</h6>
                            <h6 className="accmblviewheadi">TOMI</h6>
                        </div>
                        {data.length > 0 ? (
                            data.map((item, index) => {
                                let a = parseInt(item?.PurchaseTime);
                                let cc = new Date(a);
                                const StackIntialDate = moment(cc * 1000).format(
                                    "DD-MM-YYYY H:mm"
                                );
                                return (
                                    <>
                                        <ChildList2
                                            StackIntialDate={StackIntialDate}
                                            item={item}
                                            index={index}
                                            names={names}
                                        />
                                    </>
                                );
                            })
                        ) : loader ? (
                            <tr>
                                <td colSpan="8" className="text-center">
                                    <div className="text-center">
                                        {<Spinner animation="border" variant="info" />}
                                        {/* <h4>No Categories</h4> */}
                                    </div>
                                </td>
                            </tr>
                        ) : (
                            <p>No Records</p>
                        )}
                    </div>
                    {/* <div className="footer-content">
                        <div className="left-f">
                            {count > 0 && count <= limit ? (
                                <>
                                    <h6>
                                        SHOWING 1-{count} OF {count}
                                    </h6>
                                </>
                            ) : count > limit ? (
                                <>
                                    <h6>
                                        SHOWING {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                                        {limit * offset > count ? count : limit * offset} OF {count}
                                    </h6>
                                </>
                            ) : (
                                <>
                                    <h6>SHOWING 0 OF 0</h6>
                                </>
                            )}
                        </div> */}
                    {/* <div className="right-f">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination"> */}
                    {/* <Pagination>
                  {startPage > 1 && (
                    <>
                      <Pagination.First onClick={() => handlePageClick(1)} />
                      <Pagination.Prev
                        onClick={() => handlePageClick(currentPage - 1)}
                      />
                    </>
                  )}

                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, i) => startPage + i
                  ).map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => handlePageClick(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  {endPage < totalPages && (
                    <>
                      <Pagination.Next
                        onClick={() => handlePageClick(currentPage + 1)}
                      />
                      <Pagination.Last
                        onClick={() => handlePageClick(totalPages)}
                      />
                    </>
                  )}
                </Pagination> */}
                    {/* <Stack spacing={2}>
                                        <Pagination
                                            count={page}
                                            page={offset}
                                            onChange={(e, p) => setOffset(p)}
                                        // color="secondary"
                                        />
                                    </Stack> */}

                    {/* <li class="page-item">
                  <a class="page-link" href="#">
                    <img
                      src="\Assets\tomitoken-assets\arrow1.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link active" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    4
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    5
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <img
                      src="\Assets\tomitoken-assets\arrow2.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                </li> */}
                    {/* </ul>
                            </nav>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    );
};

export default DatatableNew;
