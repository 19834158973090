import React, { useState, useEffect } from "react";
import "./userManagementTable.scss";
import { Accordion } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "@mui/material/Pagination";
// import Pagination from "react-bootstrap/Pagination";
import Stack from "@mui/material/Stack";
import moment from "moment";
import dostsIcon from "../../Assests/dostsIcon.svg";
import deleteIcon from "../../Assests/deleteIcon.svg";
import editIcon from "../../Assests/editIcon.svg";
import viewDetailsIcon from "../../Assests/viewDetailsIcon.svg";
import Navbar from "../Navbar/Navbar";
import EditUserModal from "./EditUserModal";

const UserManagementTable = ({ setCodeDataCall, codeDataCall, codes }) => {
  const history = useHistory();
  const val = localStorage.getItem("accessToken");
  const [loader, setLoader] = useState(false);
  const [accessCodesData, setAccessCodesData] = useState([]);
  const [orderField, setOrderField] = useState("name");
  const [orderDirection, setOrderDirection] = useState("-1");
  const [usdPrice, setUsdPrice] = useState(0);
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rend, setRend] = useState(false);
  const [current, setCurrent] = useState(null)

  const [totalPages, setTotalPages] = useState(1);

  const maxVisiblePages = 5; // Maximum number of visible page numbers
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    axios
      .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
      .then((res) => {
        setUsdPrice(res?.data?.USD);
        // setEthUsd(res.data);
      });
  }, []);
  // Calculate the start and end indices for visible page numbers

  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

  // Function to handle page click
  const handlePageClick = (page) => {
    setCurrentPage(page);
    // You can perform additional actions here, such as fetching data for the selected page.
  };

  useEffect(() => {
    if (usdPrice) {
      if (val && val !== "" && val !== undefined) {
        setCodeDataCall(() => accessCodesList);
        accessCodesList();
      } else {
        history.push("/");
      }
    }
  }, [offset, orderField, orderDirection, usdPrice]);

  const accessCodesList = async () => {
    setLoader(true);
    setAccessCodesData([]);
    var config = {
      method: "get",
      url: `${Api_Url}/users?limit=${limit}&offset=${offset}&orderField=${orderField}&orderDirection=${orderDirection}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response?.data?.data;
        setTotalPages(resData?.users);
        console.log("pages: ", resData?.pages);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
        setAccessCodesData(resData?.users);

        // let sortedData = resData?.accessCodes.sort((a, b) => ((a.totalEth * usdPrice) + a.totalUsdt) - ((b.totalEth * usdPrice) + b.totalUsdt));
        // let sortedData = resData?.accessCodes.sort((a, b) => {
        //     if (a.totalUsdt === 0 && b.totalUsdt === 0) {
        //         return a.totalEth - b.totalEth; // Sort based on totalEth if both totalUsdt are 0
        //     }

        //     if (a.totalUsdt === 0) {
        //         return 1; // Place the item with totalUsdt 0 at the end
        //     }

        //     if (b.totalUsdt === 0) {
        //         return -1; // Place the item with totalUsdt 0 at the end
        //     }

        //     // Sort based on the calculated value when both totalUsdt are non-zero
        //     return ((a.totalEth * usdPrice) + a.totalUsdt) - ((b.totalEth * usdPrice) + b.totalUsdt);
        // });
        // sortedData = sortedData?.reverse();
        // setAccessCodesData(sortedData?.slice(0, 10));
        // console.log("sortedData", sortedData?.reverse())

        // setAccessCodesData(resData?.accessCodes)
        setLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }
        setLoader(false);
      });
  };

  const publishUnpublishCode = async (id) => {
    // setLoader(true);
    const config = {
      method: "patch",
      url: Api_Url + "/users/" + id + "/toggle-isaccess",
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        // setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
        }
        setLoader(false);
      });
  };

  const MoveNext = (item) => {
    localStorage.setItem("current", JSON.stringify(item));
    history.push("/leader?owner=" + item?.ownerAddress);
  };

  return (
    <>
      <section className="datatable user-mangement-table">
        <div class="table-responsivecolorbg">
          <div className="table-responsive">
            <table class="table user-table-access-wrapper">
              <thead>
                <tr>
                  <th>
                    <div
                      className="parent-tag"
                      onClick={() => {
                        setOrderField("name");
                        setOrderDirection(() => {
                          if (orderDirection == -1) return 1;
                          else return -1;
                        });
                      }}
                    >
                      Name
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag email-colll justify-content-center"
                      onClick={() => {
                        setOrderField("email");
                        setOrderDirection(() => {
                          if (orderDirection == -1) return 1;
                          else return -1;
                        });
                      }}
                    >
                      Email address
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="parent-tag access-taggg justify-content-center"
                      onClick={() => {
                        setOrderField("isAccess");
                        setOrderDirection(() => {
                          if (orderDirection == -1) return 1;
                          else return -1;
                        });
                      }}
                    >
                      Access
                      <div className="filter-btn">
                        <img
                          src="\Assets\tomitoken-assets\tableup.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\Assets\tomitoken-assets\tabledown.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag action-colll justify-content-end">
                      Action
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {accessCodesData.length > 0 ? (
                  accessCodesData.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>{item?.name}</td>
                          <td>
                            <div className="justify-content-center d-flex">
                              {item?.email}
                            </div>
                          </td>
                          <td>
                            <div class="fytgyugy justify-content-center mx-auto">
                              <input
                                type="checkbox"
                                class="checkbox"
                                id={item?._id}
                                defaultChecked={item?.isAccess}
                                onChange={() => publishUnpublishCode(item?._id)}
                              />
                              <label class="switch" for={item?._id}>
                                <span class="slider"></span>
                              </label>
                            </div>
                          </td>
                          <td>
                            <div className="dropdown dost-buttons-wrapper user-table-access-col justify-content-end">
                              <button
                                className="btn dropdown-toggle justify-content-end ml-auto d-flex w-100"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={() => {setCurrent(item); setRend(!rend)}}
                              >
                                <img src={dostsIcon} alt="dostsIcon" />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                {/* <li>
                                  <Link
                                    onClick={() => MoveNext(item)}
                                    className="dropdown-item"
                                  >
                                    <img
                                      src={viewDetailsIcon}
                                      alt="viewDetailsIcon"
                                    />
                                    View Details
                                  </Link>
                                </li> */}
                                <li>
                                  {/* <a className="dropdown-item" href="#">
                                    <img src={editIcon} alt="editIcon" />
                                    Edit
                                  </a> */}
                                  {current !== null &&
                                    <EditUserModal codes={codes} current={current} setCurrent={setCurrent} />
                                  }
                                </li>
                                {/* <li>
                                  <a className="dropdown-item" href="#">
                                    <img src={deleteIcon} alt="deleteIcon" />
                                    Delete
                                  </a>
                                </li> */}
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : loader ? (
                  <tr>
                    <td colSpan="8" className="text-center">
                      <div className="text-center">
                        {<Spinner animation="border" variant="info" />}
                        {/* <h4>No Categories</h4> */}
                      </div>
                    </td>
                  </tr>
                ) : (
                  <p className="ml-3">No Records</p>
                )}
              </tbody>
            </table>
          </div>
          <div className="accmblview d-none">
            <div className="accmainheader">
              {/* <h6 className="accmblviewheadi">
                                Date
                            </h6> */}
              <h6 className="accmblviewheadi">Name</h6>
              {/* <h6 className="accmblviewheadi">
                                TOMI
                            </h6> */}
            </div>
            {accessCodesData.length > 0 ? (
              accessCodesData.map((item, index) => {
                return (
                  <>
                    <Accordion defaultActiveKey={-1}>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header className="paddd">
                          <div className="accheadermain">
                            <p className="acctexti text-bold-h">{item?.name}</p>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="acctexts">
                            <h6 className="textleft text-email-h">
                              Email
                            </h6>
                            <p className="textright forelipsis">{item?.email}</p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Access</h6>
                            <p className="textright">
                              <div class="fytgyugy user-table-access-col">
                                <input
                                  type="checkbox"
                                  class="checkbox"
                                  id={item?._id}
                                  defaultChecked={item?.isAccess}
                                  onChange={() =>
                                    publishUnpublishCode(item?._id)
                                  }
                                />
                                <label class="switch" for={item?._id}>
                                  <span class="slider"></span>
                                </label>
                              </div>
                            </p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Action</h6>
                            <div className="dropdown dost-buttons-wrapper user-table-access-col justify-content-end">
                              <button
                                className="btn dropdown-toggle justify-content-end ml-auto d-flex w-100"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src={dostsIcon} alt="dostsIcon" />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <img src={editIcon} alt="editIcon" />
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <img src={deleteIcon} alt="deleteIcon" />
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {/* <div className="acctexts">
                            <button className="btn-view-detail" onClick={() => MoveNext(item)}>
                              View Details
                            </button>
                          </div> */}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                );
              })
            ) : loader ? (
              <tr>
                <td colSpan="8" className="text-center">
                  <div className="text-center">
                    {<Spinner animation="border" variant="info" />}
                    {/* <h4>No Categories</h4> */}
                  </div>
                </td>
              </tr>
            ) : (
              <p className="ml-3">No Records</p>
            )}
          </div>

          <div className="footer-content">
            <div className="left-f">
              {count > 0 && count <= limit ? (
                <>
                  <h6>
                    SHOWING 1-{count} OF {count}
                  </h6>
                </>
              ) : count > limit ? (
                <>
                  <h6>
                    SHOWING {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                    {limit * offset > count ? count : limit * offset} OF {count}
                  </h6>
                </>
              ) : (
                <>
                  <h6>SHOWING 0 OF 0</h6>
                </>
              )}
            </div>
            <div className="right-f">
              <Stack spacing={2}>
                <Pagination
                  count={page}
                  page={offset}
                  onChange={(e, p) => setOffset(p)}
                // color="secondary"
                />
              </Stack>
            </div>
          </div>
          {/* <div className="right-f">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination"> */}
          {/* <Pagination>
                  {startPage > 1 && (
                    <>
                      <Pagination.First onClick={() => handlePageClick(1)} />
                      <Pagination.Prev
                        onClick={() => handlePageClick(currentPage - 1)}
                      />
                    </>
                  )}

                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, i) => startPage + i
                  ).map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => handlePageClick(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  {endPage < totalPages && (
                    <>
                      <Pagination.Next
                        onClick={() => handlePageClick(currentPage + 1)}
                      />
                      <Pagination.Last
                        onClick={() => handlePageClick(totalPages)}
                      />
                    </>
                  )}
                </Pagination> */}

          {/* <li class="page-item">
                  <a class="page-link" href="#">
                    <img
                      src="\Assets\tomitoken-assets\arrow1.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link active" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    4
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    5
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <img
                      src="\Assets\tomitoken-assets\arrow2.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                </li> */}
          {/* </ul>
                            </nav>
                        </div>
                    </div> */}
        </div>
      </section>
    </>
  );
};

export default UserManagementTable;
