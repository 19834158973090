import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Dashboard from './components/accessCodes/AccessCodes';
import Login from "./Login/Login";
import LeaderHome from "./components/LeaderHome/LeaderHome";
import AccessCodes from "./components/accessCodes/AccessCodes";
import Dashboard from "./components/Dashboard/Dashboard";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/accesscode" component={AccessCodes} />
          <Route exact path="/" component={Login} />
          <Route path="/leader" component={LeaderHome} />
          <Route exact path="/dashboard" component={Dashboard} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
