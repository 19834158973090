import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import backArrow from "../../Assests/backArrow.svg";
import shoppingCart from "../../Assests/shopping-cart.svg";
import tomiLogo from "../../Assests/tomi-logo.svg";
import moneyrecive from "../../Assests/money-recive.svg";
import earningIcon from "../../Assests/earningIcon.svg";
import EarningsCodeOwners from "../../Assests/EarningsCodeOwners.svg";
import EarningsCustomers from "../../Assests/EarningsCustomers.svg";
import NetRevenue from "../../Assests/NetRevenue.svg";
import axios from "axios";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import "./alltransactions.scss";
import { Api_Url } from "../../utils/ApiUrl";
import TransactionsTable from "./TransactionsTable";
import tomilogoicon from '../../Assests/tomi-logo-icon.svg';
import dollerIcon from '../../Assests/dollerIcon.svg';


const AllTransactions = () => {
  const [codeDataCall, setCodeDataCall] = useState();
  const [data, setData] = useState([]);
  const [userObj, setUserObj] = useState(null);
  const [totalT, setTotalT] = useState(0);
  const [purchases, setPurchases] = useState(null);
  const [earnings, setEarnings] = useState(null);
  const [earningsU, setEarningsU] = useState(null);
  const [sumTomi, setSumTomi] = useState(0);
  const [usdPrice, setUsdPrice] = useState(0);
  const [tomiPrice, setchange24hr1] = useState(0);

  useEffect(() => {
    get_ApiKeys();
    axios
      .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
      .then((res) => {
        setUsdPrice(res?.data?.USD);
        // setEthUsd(res.data);
      });
  }, []);

  const get_ApiKeys = async () => {
    try {
      const response = await axios({
        method: "get",
        url: "https://tomipay-staging.tomi.com/users/private-keys",
      });
      GetCoinsList(response?.data?.keys?.AddCustomToken);
      // console.log(
      //   response?.data?.keys?.AddCustomToken,
      //   "response?.data?.keys?.AddCustomToken"
      // );
      // setApiKey(response?.data?.keys?.AddCustomToken);
    } catch (error) {
      // console.log("Error in get_ApiKeys:", await error.response);
      throw error;
    }
  };

  const GetCoinsList = async (e) => {
    let coingeckoList = await axios.get(
      `https://pro-api.coingecko.com/api/v3/coins/list?include_platform=true&x_cg_pro_api_key=${e}`
    );
    let dumArr = coingeckoList.data.filter((i) => {
      // console.log(i);
      if (i?.id === "tominet") {
        return i;
      }
    });
    // console.log(dumArr[0].id, "dumArr");
    coindata(e, dumArr[0].id);
  };

  const coindata = async (e, d) => {
    let res = await axios.get(
      `https://pro-api.coingecko.com/api/v3/coins/${d}?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false&x_cg_pro_api_key=${e}`
    );
    // console.log(res, "rsdsdes");
    // dta(res)
    let change24hr = res?.data.market_data?.price_change_percentage_24h;
    let curentprice = res.data?.market_data?.current_price?.usd;
    let imageurl = res.data?.image.small;
    setchange24hr1(change24hr);
    // console.log(change24hr, curentprice, imageurl, "fdgdsfgdssf");
  };

  useEffect(() => {
    getTotalList();
  }, []);

  const getTotalList = async () => {
    const userOb = JSON.parse(localStorage.getItem("user"));
    var data = null;
    if (userOb?.role === "user" && userOb?.limitedAccessCodes?.length > 0) {
      let cod = [];
      for (let i of userOb?.limitedAccessCodes) {
        cod?.push(i?.code)
      }
      data = JSON.stringify({
        query: `query MyQuery {
                investments(
                  where: {code_code_in: ${JSON.stringify(cod)}}
                  first: 1000
                  orderDirection: desc
                  orderBy: PurchaseTime
                ) {
                    totalTomi
                    token
                    ownerFunds
                    isClaimed
                    id
                    currentIndex
                    code_userPercentage
                    code_ownerPercentage
                    code_ownerAddress
                    code_code
                    ReleaseTime
                    PurchaseTime
                    By
                    Bonus
                    AmountTomi
                    AmountSpent
                }
              }`,
        variables: {},
      });
    } else {
      data = JSON.stringify({
        query: `query MyQuery {
                investments(
                  first: 1000
                  orderDirection: desc
                  orderBy: PurchaseTime
                ) {
                    totalTomi
                    token
                    ownerFunds
                    isClaimed
                    id
                    currentIndex
                    code_userPercentage
                    code_ownerPercentage
                    code_ownerAddress
                    code_code
                    ReleaseTime
                    PurchaseTime
                    By
                    Bonus
                    AmountTomi
                    AmountSpent
                }
              }`,
        variables: {},
      });
    }
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/41280/tomi-china/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        if (response?.data?.data?.investments?.length > 0) {
          setData(response?.data?.data?.investments);
          setUserObj(response?.data?.data?.investments[0]);
          let amountT = 0;
          let percUs = 0;
          let percEth = 0;
          let amoUs = 0;
          let amoEth = 0;
          let sumT = 0;
          let dumArr = [];
          for (let i of response?.data?.data?.investments) {
            if (
              i?.code_code !== "4402327315" &&
              i?.code_code !== "0730633351" &&
              i?.code_ownerPercentage !== "1000"
            ) {
              dumArr.push(i?.code_code);
              let amt = (parseInt(i?.AmountTomi) / 1000000000000000000).toFixed(
                4
              );
              amountT = amountT + parseFloat(amt);
              sumT = parseInt(i?.totalTomi) / 1000000000000000000 + sumT;
              if (i?.token === "ETH") {
                percEth =
                  ((parseInt(i?.AmountSpent) / 1000000000000000000) *
                    (parseInt(i?.code_ownerPercentage) / 10)) /
                  100 +
                  percEth;
                amoEth =
                  parseInt(i?.AmountSpent) / 1000000000000000000 + amoEth;
              } else {
                percUs =
                  ((parseInt(i?.AmountSpent) / 1000000) *
                    (parseInt(i?.code_ownerPercentage) / 10)) /
                  100 +
                  percUs;
                amoUs = parseInt(i?.AmountSpent) / 1000000 + amoUs;
              }
            }
            setPurchases({
              eth: amoEth,
              us: amoUs,
            });
            setEarnings({
              eth: percEth,
              us: percUs,
            });
            setTotalT(amountT);
            setSumTomi(sumT);
          }
        }
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  };

  return (
    <>
      <div className="leader-page-wrapper dashboard-wrapper-main alltransactions-page">
        {/* <NavbarNew /> */}
        <div className="dashboard-wrapper-header">
          <h3>All Transactions</h3>
          {/* <button>Create</button> */}
        </div>
        {/* <Navbar codeDataCall={codeDataCall} /> */}
        <section className="datatable">
          <div className="theme-container">
            {/* <div className='backkkk-btnn'>
                            <Link to={'/dashboard'} className='back-link'>
                                <button>
                                    <img src={backArrow} alt='backArrow' />
                                    Back
                                </button>
                            </Link>
                        </div> */}
            <div className="upperdata forresponsivemain">
              <div className="datacard forresponsiveinner color1 ">
                <div className="dataimg">
                  <img
                    src={shoppingCart}
                    alt="datainnerimg"
                    className="datainnerimg"
                  />
                </div>
                <p className="datapara">Number of Purchases</p>
                <h6 className="datahead">{data?.length}</h6>
              </div>
              <div className="datacard forresponsiveinner color2">
                <div className="dataimg">
                  <img
                    src={tomilogoicon}
                    alt="datainnerimg"
                    className="datainnerimg"
                  />
                </div>
                <p className="datapara">Total Tomi Purchased</p>
                <h6 className="datahead">
                  {parseFloat(totalT).toLocaleString("en-US")} TOMI
                </h6>
                <div className="datainfomain">
                  <div className="datainfo">
                    <span className="datainfocolortwo"></span>
                    <p className="datainfotext">
                      ~
                      {parseInt(
                        parseInt(totalT) * Math.abs(tomiPrice)
                      )?.toLocaleString("en-US")}{" "}
                      USD
                    </p>
                  </div>
                </div>
              </div>
              <div className="datacard forresponsiveinner color3">
                <div className="dataimg">
                  <img
                    src={moneyrecive}
                    alt="datainnerimg"
                    className="datainnerimg"
                  />
                </div>
                <p className="datapara">Total Purchased Amount</p>
                {usdPrice && purchases ? (
                  <h6 className="datahead">
                    ~$
                    {parseInt(
                      (usdPrice * purchases?.eth + purchases?.us)?.toFixed(2)
                    ).toLocaleString("en-US")}{" "}
                    USD
                  </h6>
                ) : (
                  <h6 className="datahead">~$0.00 USD</h6>
                )}
                <div className="datainfomain">
                  <div className="datainfo">
                    <span className="datainfocolor"></span>
                    <p className="datainfotext">
                      {purchases?.eth?.toFixed(4)} ETH
                    </p>
                  </div>
                  <div className="datainfo">
                    <span className="datainfocolortwo"></span>
                    <p className="datainfotext">
                      {parseInt(purchases?.us?.toFixed(2))?.toLocaleString(
                        "en-US"
                      )}{" "}
                      USDT
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <TransactionsTable
              setCodeDataCall={setCodeDataCall}
              codeDataCall={codeDataCall}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default AllTransactions;
