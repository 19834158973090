import React, { useState, useEffect } from "react";
import "./login.scss";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Api_Url } from "../utils/ApiUrl";

const Login = () => {
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  const userLogin = async () => {
    if (ValidateForm()) {
      const data = { email: userName, password: password, rememberMe: false };
      setLoader(true);
      await axios
        .post(`${Api_Url}/auth/users/signin`, data)
        .then((res) => {
          const resData = res?.data?.data;
          localStorage?.setItem("accessToken", resData?.accessToken);
          localStorage?.setItem("refreshToken", resData?.refreshToken);
          localStorage?.setItem("email", resData?.admin?.email);
          localStorage?.setItem("id", resData?.admin?._id);
          localStorage?.setItem("user", JSON.stringify(resData?.user));
          setLoader(false);
          history.push("/accesscode");
        })
        .catch((err) => {
          // toast.error(err?.response?.data.msg, {
          //   position: "top-right",
          //   autoClose: 2000,
          // });
          setLoader(false);
          setError(err?.response?.data?.message);
        });
    }
  };

  const [errors, setErrors] = useState({});

  const ValidateForm = () => {
    // let input = userInput;
    let errors = {};
    let isValid = true;

    if (!userName) {
      isValid = false;
      errors["username"] = "*Please enter a username";
    }

    if (!password) {
      isValid = false;
      errors["password"] = "*Please enter a password";
    }

    setErrors(errors);
    return isValid;
  };

  return (
    <>
      <section className="main-login">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-sm-12">
              <div className="maincard">
                <div className="logincard">
                  <div className="innerlogo">
                    <img src="\logo.svg" alt="img" className="img-fluid" />
                  </div>
                  <div className="cardtext">
                    <h6>log into your Admin account</h6>
                    <p>
                      Please enter your credentials below to log into your
                      account.
                    </p>
                    <input
                      type="text"
                      placeholder="Username"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e?.target?.value);
                        let tempErrors = errors;
                        tempErrors["username"] = "";
                        setErrors(errors);
                      }}
                    />
                    <div
                      style={{ position: "unset" }}
                      className="text-danger mb-4"
                    >
                      <small>{errors.username}</small>
                    </div>
                    <div className="parent">
                      <input
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => {
                          setPassword(e?.target?.value);
                          let tempErrors = errors;
                          tempErrors["password"] = "";
                          setErrors(tempErrors);
                          setError("");
                        }}
                      />
                      <div
                        style={{ position: "unset" }}
                        className="text-danger mb-4"
                      >
                        <small>{errors.password}</small>
                      </div>
                      <img
                        src="\Assets\navbar-assets\eye.svg"
                        className="img-fluid"
                        alt="img"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </div>
                  </div>
                  <div className="chec mb-4">
                    <label class="material-checkbox">
                      <input type="checkbox" />
                      <span class="checkmark"></span>
                      Keep me logged in
                    </label>
                  </div>
                  {/* <Link to="dashboard"> */}
                  <div className="mt-5">
                    <div
                      style={{ position: "unset" }}
                      className="text-danger mb-2"
                    >
                      <small>{showError}</small>
                    </div>
                    <div className="endbtn">
                      <button disabled={loader} onClick={() => userLogin()}>{loader ? "Logging In..." : "Log In"}</button>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
