import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";
import "./userManagement.scss";
import acceptIcon from "../../Assests/acceptIcon.svg";
import Multiselect from 'multiselect-react-dropdown';
import editIcon from "../../Assests/editIcon.svg";

const EditUserModal = ({ codeDataCall, codes, current }) => {
    const [name, setName] = useState("");
    const [loader, setLoader] = useState(false);
    const [rend, setRend] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [access, setAccess] = useState([]);
    const [codeNames, setCodeNames] = useState([]);
    const [codeNames2, setCodeNames2] = useState([]);
    const [selected, setSelected] = useState([]);
    const [check, setCheck] = useState("")

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setshow1] = useState(false);

    const handleSelect = (selectedList, selectedItem) => {
        console.log("selectedList", selectedList)
        setSelected(selectedList);
        // Handle selection
    };

    useEffect(() => {
        if (current) {
            setName(current?.name)
            setAccess(current?.access)
            if (current?.allAccessCodes) {
                setCheck("all")
            } else {
                let dumObj = current?.access?.find((i) => {
                    return i === "access codes";
                })
                if (dumObj) {
                    setCheck("lim")
                } else if (current?.limitedAccessCodes?.length > 0) {
                    setCheck("lim")
                }
            }
            if (current?.limitedAccessCodes?.length > 0) {
                let dumArr = [];
                for (let i of current?.limitedAccessCodes) {
                    dumArr.push(i?.code)
                }
                setCodeNames2(dumArr)
            }
        }
    }, [current])

    useEffect(() => {
        if (codes?.length > 0) {
            let dumArr = [];
            for (let i of codes) {
                let val = i?.code + " " + `(${i?.name})`
                dumArr.push(val)
                // dumArr.push(i?.code)
            }
            setCodeNames(dumArr);
        }
    }, [codes])

    const CheckAccess = (val) => {
        let dumArr = access;
        let dumObj = null;
        dumObj = dumArr?.find((i) => {
            return i === val;
        });
        if (dumObj) {
            let indexof = dumArr?.indexOf((i) => {
                return i === val;
            });
            dumArr?.splice(indexof, 1);
        } else {
            dumArr.push(val);
        }
        setAccess(dumArr);
        setRend(!rend);
    };

    const CheckMatch = (val) => {
        let dumObj = access?.find((i) => {
            return i === val;
        });
        if (dumObj) {
            return true;
        } else {
            return false;
        }
    };

    const VerifyCheck = (boo, val) => {
        if (boo) {
            if (val === "all") {
                let dumArr = access;
                dumArr.push("access codes");
                setAccess(dumArr)
            } else if (val === "lim") {

                let dumArr = access;
                let dumObj = null;
                dumObj = dumArr?.find((i) => {
                    return i === "access codes";
                });
                if (dumObj) {
                    let indexof = dumArr?.findIndex((i) => {
                        return i === "access codes";
                    });
                    if (indexof > -1) {
                        dumArr?.splice(indexof, 1);
                        setAccess(dumArr)
                    }
                }
            }
            setCheck(val)
            setRend(!rend)
        } else {
            setCheck("")
        }
    }

    const EditUser = () => {
        setError("");
        let locArr = [];
        for (let i of selected) {
            let dumObj = codes?.find((io) => {
                const inputString = i;
                const parts = inputString.split(' ');
                const stringBeforeWhitespace = parts[0];
                return stringBeforeWhitespace === io.code;
            })
            locArr.push(dumObj?._id);
        }
        if (name?.length > 2) {
            if (access?.length > 0) {
                setLoader(true);
                const val = localStorage.getItem("accessToken");
                var config = {
                    method: "patch",
                    url: `${Api_Url}/users/${current?._id}`,
                    headers: {
                        Authorization: "Bearer " + val,
                    },
                    data: {
                        name: name,
                        access: access,
                        limitedAccessCodes: locArr,
                        allAccessCodes: check === "all" ? true : false
                    },
                };

                axios(config)
                    .then(function (response) {
                        setshow1(true);
                        codeDataCall();
                        setLoader(false);
                    })
                    .catch(function (error) {
                        setLoader(false);
                    });
            } else {
                setError("Choose at least one access option");
            }
        } else {
            setError("*Name must be at least 3 character long");
        }
    };

    return (
        <>
            {/* <!-- Button trigger modal --> */}
            {/* <Button onClick={handleShow}> */}
            <a className="dropdown-item" onClick={handleShow}>
                <img src={editIcon} alt="editIcon" />
                Edit
            </a>
            {/* </Button> */}

            <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="add-user-modal-wrapper"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {show1 ? (
                        <div className="check-succcessful">
                            <img src={acceptIcon} alt="acceptIcon" />
                            <h3>User Edited</h3>
                            {/* <p>Instructions have been emailed to the user</p> */}
                        </div>
                    ) : (
                        <>
                            <p className="modalpara">Name</p>
                            <div className="maindateinput">
                                <input
                                    type="text"
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter Name"
                                    value={name}
                                    className="dateinput"
                                // value={name}
                                // onChange={(e) => {
                                //     setName(e.target.value);
                                //     let tempErr = errors;
                                //     tempErr["name"] = "";
                                //     setErrors(tempErr);
                                // }}
                                />
                                <div style={{ position: "unset" }} className="text-danger mb-4">
                                    {/* <small>{errors.name}</small> */}
                                </div>
                            </div>
                            <p className="modalpara">Access</p>
                            <div className="modal-checks-wrapper">
                                <div className="chec mb-4">
                                    <label class="material-checkbox">
                                        <input
                                            checked={CheckMatch("dashboard")}
                                            onChange={() => CheckAccess("dashboard")}
                                            type="checkbox"
                                        />
                                        <span class="checkmark"></span>
                                        Dashboard
                                    </label>
                                </div>
                                <div className="chec mb-4">
                                    <label class="material-checkbox">
                                        <input
                                            checked={CheckMatch("access codes")}
                                            onChange={() => CheckAccess("access codes")}
                                            disabled={check !== ""}
                                            type="checkbox"
                                        />
                                        <span class="checkmark"></span>
                                        Access Codes
                                    </label>
                                </div>
                                <div className="chec mb-4">
                                    <label class="material-checkbox">
                                        <input
                                            checked={CheckMatch("all transactions")}
                                            onChange={() => CheckAccess("all transactions")}
                                            type="checkbox"
                                        />
                                        <span class="checkmark"></span>
                                        All Transactions
                                    </label>
                                </div>
                                <div className="chec mb-4">
                                    <label class="material-checkbox">
                                        <input
                                            checked={CheckMatch("user management")}
                                            onChange={() => CheckAccess("user management")}
                                            type="checkbox"
                                        />
                                        <span class="checkmark"></span>
                                        User Management
                                    </label>
                                </div>
                            </div>
                            <p style={{ color: "red", textAlign: "center", paddingBottom: 24 }}>
                                {error}
                            </p>


                            <p className="modalpara">Access Code</p>
                            <div className="acces-code-radio-input-wrapper">
                                <div className="acces-code-radio-input-item">
                                    <input checked={check === "all"} onChange={(e) => VerifyCheck(e.target.checked, "all")} type="checkbox" id="code1" name="input-radio" value="" />
                                    <label for="code1">All Access code</label>
                                </div>
                                <div className="acces-code-radio-input-item">
                                    <input checked={check === "lim"} onChange={(e) => VerifyCheck(e.target.checked, "lim")} type="checkbox" id="code2" name="input-radio" value="" />
                                    <label for="code2">Limited Access Codes</label>
                                </div>
                            </div>


                            {/* <div> */}
                            {check === "lim" &&
                                <>
                                    <p className="modalpara access-code-multiselect">Select Access Code</p>
                                    <Multiselect
                                        className="my-multiselect-dropdowns"
                                        isObject={false}
                                        placeholder="Select Access Code"
                                        // onKeyPressFn={function noRefCheck() { }}
                                        // onRemove={function noRefCheck() { }}
                                        // onSearch={function noRefCheck() { }}
                                        onSelect={handleSelect}
                                        // closeMenu={true}
                                        options={codeNames}
                                        selectedValues={codeNames2}
                                        displayValue="code"
                                        // options={options}
                                        isOpen={false} // Set isOpen prop to false
                                    />
                                </>
                            }
                            {/* </div> */}
                        </>
                    )}

                </Modal.Body>
                <div class="modalbtns">
                    {show1 ? (
                        <button
                            onClick={() => {
                                handleClose();
                                setshow1(false);
                            }}
                            class="unhoverbtn"
                        >
                            Okay
                        </button>
                    ) : (
                        <>
                            <button
                                disabled={loader}
                                class="hoverbtn"
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={loader}
                                class="unhoverbtn"
                                onClick={() => EditUser()}
                            >
                                {loader ? "Editing user..." : "Save"}
                            </button>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default EditUserModal;
