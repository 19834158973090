import React, { useState, useEffect } from "react";
import NavbarNew from "../Navbar/NavbarNew";
import backArrow from "../../Assests/backArrow.svg";
import shoppingCart from "../../Assests/shopping-cart.svg";
import tomiLogo from "../../Assests/tomi-logo.svg";
import moneyrecive from "../../Assests/money-recive.svg";
import earningIcon from "../../Assests/earningIcon.svg";
import externalIcon from "../../Assests/externalIcon.svg";
import EarningsCodeOwners from "../../Assests/EarningsCodeOwners.svg";
import EarningsCustomers from "../../Assests/EarningsCustomers.svg";
import NetRevenue from "../../Assests/NetRevenue.svg";
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import "./userManagement.scss";
import DatatableNew from "../Dashboard/DatatableNew";
import AddUserModal from "./AddUserModal";
import UserManagementTable from "./UserManagementTable";
// import DatatableNew from './DatatableNew';

const UserManagement = () => {
  const [codeDataCall, setCodeDataCall] = useState();
  const [data, setData] = useState([]);
  const [codes, setCodes] = useState([]);
  const [userObj, setUserObj] = useState(null);
  const [totalT, setTotalT] = useState(0);
  const [purchases, setPurchases] = useState(null);
  const [earnings, setEarnings] = useState(null);
  const [earningsU, setEarningsU] = useState(null);
  const [sumTomi, setSumTomi] = useState(0);
  const [usdPrice, setUsdPrice] = useState(0);
  const [tomiPrice, setchange24hr1] = useState(0);

  useEffect(() => {
    get_ApiKeys();
    axios
      .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
      .then((res) => {
        setUsdPrice(res?.data?.USD);
        // setEthUsd(res.data);
      });
  }, []);

  const get_ApiKeys = async () => {
    try {
      const response = await axios({
        method: "get",
        url: "https://tomipay-staging.tomi.com/users/private-keys",
      });
      GetCoinsList(response?.data?.keys?.AddCustomToken);
      // console.log(
      //   response?.data?.keys?.AddCustomToken,
      //   "response?.data?.keys?.AddCustomToken"
      // );
      // setApiKey(response?.data?.keys?.AddCustomToken);
    } catch (error) {
      // console.log("Error in get_ApiKeys:", await error.response);
      throw error;
    }
  };

  const GetCoinsList = async (e) => {
    let coingeckoList = await axios.get(
      `https://pro-api.coingecko.com/api/v3/coins/list?include_platform=true&x_cg_pro_api_key=${e}`
    );
    let dumArr = coingeckoList.data.filter((i) => {
      // console.log(i);
      if (i?.id === "tominet") {
        return i;
      }
    });
    // console.log(dumArr[0].id, "dumArr");
    coindata(e, dumArr[0].id);
  };

  const coindata = async (e, d) => {
    let res = await axios.get(
      `https://pro-api.coingecko.com/api/v3/coins/${d}?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false&x_cg_pro_api_key=${e}`
    );
    // console.log(res, "rsdsdes");
    // dta(res)
    let change24hr = res?.data.market_data?.price_change_percentage_24h;
    let curentprice = res.data?.market_data?.current_price?.usd;
    let imageurl = res.data?.image.small;
    setchange24hr1(change24hr);
    // console.log(change24hr, curentprice, imageurl, "fdgdsfgdssf");
  };

  useEffect(() => {
    getTotalList();
    getAllAccessCodes();
  }, []);

  const getTotalList = async () => {
    var data = JSON.stringify({
      query: `query MyQuery {
                investments(
                  orderDirection: desc
                  orderBy: PurchaseTime
                ) {
                    totalTomi
                    token
                    ownerFunds
                    isClaimed
                    id
                    currentIndex
                    code_userPercentage
                    code_ownerPercentage
                    code_ownerAddress
                    code_code
                    ReleaseTime
                    PurchaseTime
                    By
                    Bonus
                    AmountTomi
                    AmountSpent
                }
              }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/41280/tomi-china/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        if (response?.data?.data?.investments?.length > 0) {
          setData(response?.data?.data?.investments);
          setUserObj(response?.data?.data?.investments[0]);
          let amountT = 0;
          let percUs = 0;
          let percEth = 0;
          let amoUs = 0;
          let amoEth = 0;
          let sumT = 0;
          for (let i of response?.data?.data?.investments) {
            let amt = (parseInt(i?.AmountTomi) / 1000000000000000000).toFixed(
              4
            );
            amountT = amountT + parseFloat(amt);
            sumT = parseInt(i?.totalTomi) / 1000000000000000000 + sumT;
            if (i?.token === "ETH") {
              percEth =
                ((parseInt(i?.AmountSpent) / 1000000000000000000) *
                  (parseInt(i?.code_ownerPercentage) / 10)) /
                100 +
                percEth;
              amoEth = parseInt(i?.AmountSpent) / 1000000000000000000 + amoEth;
            } else {
              percUs =
                ((parseInt(i?.AmountSpent) / 1000000) *
                  (parseInt(i?.code_ownerPercentage) / 10)) /
                100 +
                percUs;
              amoUs = parseInt(i?.AmountSpent) / 1000000 + amoUs;
            }
          }
          setPurchases({
            eth: amoEth,
            us: amoUs,
          });
          setEarnings({
            eth: percEth,
            us: percUs,
          });
          setTotalT(amountT);
          setSumTomi(sumT);
        }
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  };

  const getAllAccessCodes = () => {
    const val = localStorage.getItem("accessToken");
    var config = {
      method: "get",
      url: `${Api_Url}/access-codes/minimal?limit=${200}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        setCodes(response?.data?.data)
        // setLoader(false);
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  return (
    <>
      <div className="leader-page-wrapper dashboard-wrapper-main">
        {/* <NavbarNew key={"dash"} /> */}
        <div className="dashboard-wrapper-header">
          <h3>User Management</h3>
          {/* <button>Create</button> */}
          <AddUserModal codes={codes} codeDataCall={codeDataCall} />
        </div>
        <section className="datatable">
          <div className="theme-container">
            <UserManagementTable
              setCodeDataCall={setCodeDataCall}
              codes={codes}
              codeDataCall={codeDataCall}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default UserManagement;
