import React, { useEffect, useState } from "react";
import "./navbar.scss";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";
import { Modal } from "react-bootstrap";
import moment from "moment";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { errors } from "web3-core-helpers";

const Navbar = ({ codeDataCall }) => {
  const history = useHistory();
  const val = localStorage.getItem("accessToken");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setName("");
    setEndDate(moment.utc());
    setStartDate(moment.utc());
    setWalletAddress("");
    setOwnerPercentage("");
    setUserPercentage("");
  };
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => {
    setShow1(false);
    setAccessCode("");
    setAccessCodeCopied(false);

    setName("");
    setEndDate(moment.utc());
    setStartDate(moment.utc());
    setWalletAddress("");
    setOwnerPercentage("");
    setUserPercentage("");
  };
  const handleShow1 = () => setShow1(true);
  const [name, setName] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [ownerPercentage, setOwnerPercentage] = useState("");
  const [userPercentage, setUserPercentage] = useState("");

  //   const [nameError, setNameError] = useState("");
  const [errors, setErrors] = useState({});

  const [startDate, setStartDate] = useState(moment.utc());
  const [endDate, setEndDate] = useState(moment.utc());
  const [accessCode, setAccessCode] = useState("");
  const [accessCodeCopied, setAccessCodeCopied] = useState(false);

  const createCode = async () => {
    if (ValidateForm()) {
      var config = {
        method: "post",
        url: `${Api_Url}/access-codes`,
        data: {
          ownerAddress: walletAddress,
          ownerPercentage: ownerPercentage * 10,
          userPercentage: userPercentage * 10,
          name: name,
          startDate: startDate,
          endDate: endDate,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };

      axios(config)
        .then(function (response) {
          setAccessCode(response?.data?.data?.code);
          codeDataCall();
          handleClose();
          handleShow1();
        })
        .catch(function (error) {
          if (error?.response?.status == 401) {
            localStorage.removeItem("accessToken");
            history.push("/");
          }
        });
    }
  };
  function validateInputAddresses(address) {
    return /^(0x){1}[0-9a-fA-F]{40}$/i.test(address);
  }

  const ValidateForm = () => {
    // let input = userInput;
    let errors = {};
    let isValid = true;

    if (!name) {
      isValid = false;
      errors["name"] = "*Please enter a name";
    }
    if (!ownerPercentage || ownerPercentage < 0 || ownerPercentage > 100) {
      isValid = false;
      errors["ownerPercentage"] = "*Please enter a valid owner's percentage";
    }
    if (!userPercentage || userPercentage < 0 || userPercentage > 100) {
      isValid = false;
      errors["userPercentage"] = "*Please enter valid user's percentage";
    }
    if (!validateInputAddresses(walletAddress)) {
      isValid = false;
      errors["walletAddress"] = "*Please enter a valid address";
    }
    setErrors(errors);
    return isValid;
  };

  return (
    <>
      <section className="main-navbar border-bottom-0">
        <div className="container-fluid p-0">
          <div className="navbar">
            <h4 className="genratedtext">Generated Access Codes</h4>
            <button onClick={handleShow} className="pinkbtn">
              Create
            </button>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modalpara">Name</p>
          <div className="maindateinput">
            <input
              type="text"
              placeholder="Enter Name"
              className="dateinput"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                let tempErr = errors;
                tempErr["name"] = "";
                setErrors(tempErr);
              }}
            />
            <div style={{ position: "unset" }} className="text-danger mb-4">
              <small>{errors.name}</small>
            </div>
          </div>
          <p className="modalpara">Owner's Wallet Address</p>
          <div className="maindateinput">
            <input
              type="text"
              placeholder="Enter Wallet Address"
              className="dateinput"
              value={walletAddress}
              onChange={(e) => {
                setWalletAddress(e.target.value);
                let tempErr = errors;
                tempErr["walletAddress"] = "";
                setErrors(tempErr);
              }}
            />
            <div style={{ position: "unset" }} className="text-danger mb-4">
              <small>{errors.walletAddress}</small>
            </div>
          </div>
          <p className="modalpara">Owner's Percentage</p>
          <div className="maindateinput">
            <input
              type="text"
              placeholder="Enter Owner's Percentage"
              className="dateinput"
              value={ownerPercentage}
              onChange={(e) => {
                // const regex = /^\d*\.?\d{0,1}$/;

                // Check if the input value matches the regex
                if (
                  e.target.value === "" ||
                  (e.target.value <= 20 &&
                    /^\d*\.?\d{0,1}$/.test(e.target.value))
                ) {
                  setOwnerPercentage(e.target.value);
                  let tempErr = errors;
                  tempErr["ownerPercentage"] = "";
                  setErrors(tempErr);
                }
              }}
            />
            <div style={{ position: "unset" }} className="text-danger mb-4">
              <small>{errors.ownerPercentage}</small>
            </div>
          </div>
          <p className="modalpara">User's Percentage</p>
          <div className="maindateinput">
            <input
              type="text"
              placeholder="Enter User's Percentage"
              className="dateinput"
              value={userPercentage}
              onChange={(e) => {
                const regex = /^\d*\.?\d{0,1}$/;

                // Check if the input value matches the regex
                if (regex.test(e.target.value)) {
                  setUserPercentage(e.target.value);
                  let tempErr = errors;
                  tempErr["userPercentage"] = "";
                  setErrors(tempErr);
                }
              }}
            />
            <div style={{ position: "unset" }} className="text-danger mb-4">
              <small>{errors.userPercentage}</small>
            </div>
          </div>
          <p className="modalpara">Start Date</p>
          <div className="maindateinput">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Stack spacing={2}>
                <DateTimePicker
                  value={startDate}
                  onChange={setStartDate}
                  maxDateTime={endDate}
                  ampm={false}
                />
              </Stack>
            </LocalizationProvider>
            {/* <input
              type="text"
              placeholder="Enter Start Date"
              className="dateinput"
            /> */}
            {/* <img
              src="\Assets\navbar-assets\dateimg.svg"
              alt="dateimg"
              className="dateimg"
            /> */}
          </div>
          <p className="modalpara">End Date</p>
          <div className="maindateinput">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Stack spacing={2}>
                <DateTimePicker
                  value={endDate}
                  onChange={setEndDate}
                  minDateTime={startDate}
                  ampm={false}
                />
              </Stack>
            </LocalizationProvider>
            {/* <input
              type="text"
              placeholder="Enter End Date"
              className="dateinput"
            />
            <img
              src="\Assets\navbar-assets\dateimg.svg"
              alt="dateimg"
              className="dateimg"
            /> */}
          </div>
          <div className="modalbtns">
            <button className="hoverbtn">Back</button>
            <button
              onClick={() => {
                createCode();
              }}
              className="unhoverbtn"
            >
              Finish
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modalparamain">Your Access Code has been created</p>
          <p className="copypara">
            {accessCode}
            <img
              onClick={() => {
                navigator.clipboard.writeText(accessCode);
                setAccessCodeCopied(true);
              }}
              src="\Assets\navbar-assets\copy.svg"
              alt="copyimg"
              className="copyimg"
            />
          </p>
          {accessCodeCopied ? <p className="copypara">Copied!</p> : null}

          <div className="modalbtns">
            <button
              className="unhoverbtn"
              onClick={() => {
                handleClose1();
              }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
