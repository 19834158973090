import React, { useEffect, useState } from "react";
import "./navbarNew.scss";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { ConnectButton } from '@rainbow-me/rainbowkit';
import logout from "../../Assests/logout.svg";

const NavbarNew = ({ key }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [windowSize, setWindowSize] = useState(window.innerWidth);
  // console.log(windowSize, 'windowSize');
  // useEffect(() => {
  //   const handleWindowResize = () => {
  //     setWindowSize(window.innerWidth);
  //   };

  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, []);


  return (
    <>
      <section className="main-navbar new-new-barrr">
        <div className="theme-container">
          <div className="navbar">
            <Link to="/">
              <img src="\logo.svg" alt="logomain" className="logomain" />
            </Link>
            <button onClick={handleShow} className="newnav-logoutbtn">
              <img src={logout} alt="dicnctimg" className="dicnctimg" />
              {/* {
                windowSize <= 600 ?
                  'Logout'
                  :
                  "Disconnect"
              } */}
              Logout
            </button>
            {/* <ConnectButton chainStatus="none" /> */}
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalcardmain">
            <div className="innercard">
              <img
                src="\Assets\navbar-assets\metamask.svg"
                alt="modalimg"
                className="modalimg"
              />
              <p className="modaltext">Metamask</p>
            </div>
            <div className="innercard">
              <img
                src="\Assets\navbar-assets\walletconnect.svg"
                alt="modalimg"
                className="modalimg"
              />
              <p className="modaltext">WalletConnect</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NavbarNew;
