import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Link, useLocation, useHistory } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Datatable from "../Datatable/Datatable";
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import Dashboard from "../Dashboard/Dashboard";
import AllTransactions from "../AllTransactions/AllTransactions";
import UserManagement from "../userManagement/UserManagement";
const AccessCodes = (props) => {
  const propRoute = props?.location?.state;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showSide, setShowSide] = useState();
  const [codeDataCall, setCodeDataCall] = useState();
  const [currentTab, setCurrentTab] = useState(null);
  const [user, setUser] = useState(null);

  const val = localStorage.getItem("accessToken");

  const history = useHistory();
  const sidebar = () => {
    if (showSide === true) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  };

  const location = useLocation();
  const currentpath = location.pathname;
  const [route, setroute] = useState(0);


  useEffect(() => {
    const userOb = JSON.parse(localStorage.getItem("user"));
    setUser(userOb);
    let dumArr = userOb?.access;
    if (userOb?.role === "user") {
      if (propRoute?.tabKey === "second") {
        setCurrentTab("second")
      } else {
        dumArr = dumArr[0];
        if (dumArr === "dashboard") {
          setCurrentTab("first")
        } else if (dumArr === "access codes") {
          setCurrentTab("second")
        } else if (dumArr === "all transactions") {
          setCurrentTab("third")
        } else if (dumArr === "user management") {
          setCurrentTab("forth")
        }
      }
    } else {
      setCurrentTab(propRoute?.tabKey === "second" ? "second" : "first")
    }
  }, [])


  const CheckAccess = (val) => {
    const userOb = JSON.parse(localStorage.getItem("user"));
    let userObj = userOb?.access?.find((i) => {
      return i === val && userOb?.role === "user";
    });
    if (userObj) {
      return true;
    } else {
      if (userOb?.isAccess === true && val === "access codes") {
        if (userOb?.limitedAccessCodes?.length > 0) {
          return true;
        } else {
          return false;
        }
      } else if (userOb?.role === "admin") {
        return true;
      } else {
        return false;
      }
    }
  };

  const Logout = async () => {
    // setLoader(true);

    var config = {
      method: "delete",
      url: `${Api_Url}/auth/users/logout`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        if (response?.data?.statusCode == 200) {
          localStorage?.removeItem("accessToken");
          localStorage?.removeItem("refreshToken");
          localStorage?.removeItem("userName");

          history.push("/");
        }
        // setLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }

        // if(error?.response?.status==401){
        //     RefreshToken()
        // }
        // setLoader(false);
      });
  };

  return (
    <>
      <section className="maindashboard">
        <div className="theme-container">
          <div onClick={sidebar} className="sidebar-icon">
            <div className="sidebarheader">
              <img
                src="\logo.svg"
                alt="mobilelogo"
                className="mobilelogo d-none"
              />
            </div>
            <div className="lefticons">
              <img
                src="\Assets\Sidebar-icons\toggler.svg"
                alt="img"
                className="img-fluid toggler"
              />
            </div>
          </div>
          {currentTab &&
            <Tab.Container id="left-tabs-example" defaultActiveKey={currentTab}>
              <div className="lowerdashboardmain">
                <div
                  className={
                    showSide === true
                      ? "mobielSidebarShow"
                      : showSide === false
                        ? "mobielSidebarHide dashboardddd"
                        : "mobile-side-none"
                  }
                >
                  <div className="sidebarheader d-none">
                    <div className="sidebarheaderss">
                      <img
                        onClick={() => {
                          setroute(0);
                        }}
                        src="\logo.svg"
                        alt="sidebarheaderlogo"
                        className="sidebarheaderlogo"
                      />
                    </div>
                    <div className="lefticons">
                      <img
                        src="\Assets\Sidebar-icons\close-circle.svg"
                        alt="img"
                        className="img-fluid close-btn d-none"
                        onClick={sidebar}
                      />
                    </div>
                  </div>
                  <Nav variant="pills" className="sidebar">
                    <div className="sidebarheader">
                      <img
                        onClick={() => {
                          setroute(0);
                        }}
                        src="\logo.svg"
                        alt="logoside"
                        className="logoside"
                      />
                    </div>
                    {CheckAccess("dashboard") && (
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          onClick={() => {
                            sidebar();
                            setroute(0);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M19.77 11.25H15.73C13.72 11.25 12.75 10.36 12.75 8.52V3.98C12.75 2.14 13.73 1.25 15.73 1.25H19.77C21.78 1.25 22.75 2.14 22.75 3.98V8.51C22.75 10.36 21.77 11.25 19.77 11.25ZM15.73 2.75C14.39 2.75 14.25 3.13 14.25 3.98V8.51C14.25 9.37 14.39 9.74 15.73 9.74H19.77C21.11 9.74 21.25 9.36 21.25 8.51V3.98C21.25 3.12 21.11 2.75 19.77 2.75H15.73Z"
                              fill="#063540"
                            />
                            <path
                              d="M19.77 22.75H15.73C13.72 22.75 12.75 21.77 12.75 19.77V15.73C12.75 13.72 13.73 12.75 15.73 12.75H19.77C21.78 12.75 22.75 13.73 22.75 15.73V19.77C22.75 21.77 21.77 22.75 19.77 22.75ZM15.73 14.25C14.55 14.25 14.25 14.55 14.25 15.73V19.77C14.25 20.95 14.55 21.25 15.73 21.25H19.77C20.95 21.25 21.25 20.95 21.25 19.77V15.73C21.25 14.55 20.95 14.25 19.77 14.25H15.73Z"
                              fill="#063540"
                            />
                            <path
                              d="M8.27 11.25H4.23C2.22 11.25 1.25 10.36 1.25 8.52V3.98C1.25 2.14 2.23 1.25 4.23 1.25H8.27C10.28 1.25 11.25 2.14 11.25 3.98V8.51C11.25 10.36 10.27 11.25 8.27 11.25ZM4.23 2.75C2.89 2.75 2.75 3.13 2.75 3.98V8.51C2.75 9.37 2.89 9.74 4.23 9.74H8.27C9.61 9.74 9.75 9.36 9.75 8.51V3.98C9.75 3.12 9.61 2.75 8.27 2.75H4.23Z"
                              fill="#063540"
                            />
                            <path
                              d="M8.27 22.75H4.23C2.22 22.75 1.25 21.77 1.25 19.77V15.73C1.25 13.72 2.23 12.75 4.23 12.75H8.27C10.28 12.75 11.25 13.73 11.25 15.73V19.77C11.25 21.77 10.27 22.75 8.27 22.75ZM4.23 14.25C3.05 14.25 2.75 14.55 2.75 15.73V19.77C2.75 20.95 3.05 21.25 4.23 21.25H8.27C9.45 21.25 9.75 20.95 9.75 19.77V15.73C9.75 14.55 9.45 14.25 8.27 14.25H4.23Z"
                              fill="#063540"
                            />
                          </svg>
                          Dashboard
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {/* {CheckAccess("access codes") && ( */}
                    {user?.role === "admin" && (
                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          onClick={() => {
                            sidebar();
                            setroute(0);
                          }}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.01 19.26C19.82 19.26 19.63 19.19 19.48 19.04L14.53 14.09C14.24 13.8 14.24 13.32 14.53 13.03C14.82 12.74 15.3 12.74 15.59 13.03L20.54 17.98C20.83 18.27 20.83 18.75 20.54 19.04C20.39 19.19 20.2 19.26 20.01 19.26Z"
                              fill="#063540"
                            />
                            <path
                              d="M10.1099 18.43C9.37995 18.43 8.67995 18.14 8.16995 17.62L3.92994 13.38C2.85994 12.31 2.85994 10.56 3.92994 9.49003L10.9999 2.42005C12.0699 1.35005 13.8199 1.35005 14.8899 2.42005L19.13 6.66004C19.65 7.18004 19.94 7.87005 19.94 8.60005C19.94 9.33005 19.65 10.03 19.13 10.54L12.0599 17.61C11.5399 18.15 10.8499 18.43 10.1099 18.43ZM12.94 3.12004C12.62 3.12004 12.2999 3.24003 12.0599 3.49003L4.98995 10.56C4.49995 11.05 4.49995 11.84 4.98995 12.33L9.22994 16.57C9.69994 17.04 10.5199 17.04 10.9999 16.57L18.07 9.50004C18.31 9.26004 18.44 8.95004 18.44 8.62004C18.44 8.29004 18.31 7.97003 18.07 7.74003L13.83 3.50004C13.58 3.24004 13.26 3.12004 12.94 3.12004Z"
                              fill="#063540"
                            />
                            <path
                              d="M8 21.75H2C1.59 21.75 1.25 21.41 1.25 21C1.25 20.59 1.59 20.25 2 20.25H8C8.41 20.25 8.75 20.59 8.75 21C8.75 21.41 8.41 21.75 8 21.75Z"
                              fill="#063540"
                            />
                            <path
                              d="M13.63 15.74C13.44 15.74 13.25 15.67 13.1 15.52L6.03 8.44998C5.74 8.15998 5.74 7.67999 6.03 7.38999C6.32 7.09999 6.8 7.09999 7.09 7.38999L14.16 14.46C14.45 14.75 14.45 15.23 14.16 15.52C14.02 15.67 13.82 15.74 13.63 15.74Z"
                              fill="#063540"
                            />
                          </svg>
                          Access Codes
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {CheckAccess("all transactions") && (
                      <Nav.Item>
                        <Nav.Link
                          eventKey="third"
                          onClick={() => {
                            sidebar();
                            setroute(0);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M15.4897 20.76C15.2997 20.76 15.1097 20.69 14.9597 20.54C14.6697 20.25 14.6697 19.77 14.9597 19.48L19.9697 14.47C20.2597 14.18 20.7397 14.18 21.0297 14.47C21.3197 14.76 21.3197 15.24 21.0297 15.53L16.0197 20.54C15.8697 20.68 15.6797 20.76 15.4897 20.76Z"
                              fill="#063540"
                            />
                            <path
                              d="M20.5 15.74H3.5C3.09 15.74 2.75 15.4 2.75 14.99C2.75 14.58 3.09 14.24 3.5 14.24H20.5C20.91 14.24 21.25 14.58 21.25 14.99C21.25 15.4 20.91 15.74 20.5 15.74Z"
                              fill="#063540"
                            />
                            <path
                              d="M3.50043 9.76C3.31043 9.76 3.12043 9.69 2.97043 9.54C2.68043 9.25 2.68043 8.77 2.97043 8.48L7.98043 3.47C8.27043 3.18 8.75043 3.18 9.04043 3.47C9.33043 3.76 9.33043 4.24 9.04043 4.53L4.03043 9.54C3.89043 9.68 3.69043 9.76 3.50043 9.76Z"
                              fill="#063540"
                            />
                            <path
                              d="M20.5 9.75999H3.5C3.09 9.75999 2.75 9.41999 2.75 9.00999C2.75 8.59999 3.09 8.25999 3.5 8.25999H20.5C20.91 8.25999 21.25 8.59999 21.25 9.00999C21.25 9.41999 20.91 9.75999 20.5 9.75999Z"
                              fill="#063540"
                            />
                          </svg>
                          All Transactions
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {CheckAccess("user management") && (
                      <Nav.Item>
                        <Nav.Link
                          eventKey="forth"
                          onClick={() => {
                            sidebar();
                            setroute(0);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M9.15957 11.62C9.12957 11.62 9.10957 11.62 9.07957 11.62C9.02957 11.61 8.95957 11.61 8.89957 11.62C5.99957 11.53 3.80957 9.25 3.80957 6.44C3.80957 3.58 6.13957 1.25 8.99957 1.25C11.8596 1.25 14.1896 3.58 14.1896 6.44C14.1796 9.25 11.9796 11.53 9.18957 11.62C9.17957 11.62 9.16957 11.62 9.15957 11.62ZM8.99957 2.75C6.96957 2.75 5.30957 4.41 5.30957 6.44C5.30957 8.44 6.86957 10.05 8.85957 10.12C8.91957 10.11 9.04957 10.11 9.17957 10.12C11.1396 10.03 12.6796 8.42 12.6896 6.44C12.6896 4.41 11.0296 2.75 8.99957 2.75Z"
                              fill="#063540"
                            />
                            <path
                              d="M16.5394 11.75C16.5094 11.75 16.4794 11.75 16.4494 11.74C16.0394 11.78 15.6194 11.49 15.5794 11.08C15.5394 10.67 15.7894 10.3 16.1994 10.25C16.3194 10.24 16.4494 10.24 16.5594 10.24C18.0194 10.16 19.1594 8.96 19.1594 7.49C19.1594 5.97 17.9294 4.74 16.4094 4.74C15.9994 4.75 15.6594 4.41 15.6594 4C15.6594 3.59 15.9994 3.25 16.4094 3.25C18.7494 3.25 20.6594 5.16 20.6594 7.5C20.6594 9.8 18.8594 11.66 16.5694 11.75C16.5594 11.75 16.5494 11.75 16.5394 11.75Z"
                              fill="#063540"
                            />
                            <path
                              d="M9.16961 22.55C7.20961 22.55 5.23961 22.05 3.74961 21.05C2.35961 20.13 1.59961 18.87 1.59961 17.5C1.59961 16.13 2.35961 14.86 3.74961 13.93C6.74961 11.94 11.6096 11.94 14.5896 13.93C15.9696 14.85 16.7396 16.11 16.7396 17.48C16.7396 18.85 15.9796 20.12 14.5896 21.05C13.0896 22.05 11.1296 22.55 9.16961 22.55ZM4.57961 15.19C3.61961 15.83 3.09961 16.65 3.09961 17.51C3.09961 18.36 3.62961 19.18 4.57961 19.81C7.06961 21.48 11.2696 21.48 13.7596 19.81C14.7196 19.17 15.2396 18.35 15.2396 17.49C15.2396 16.64 14.7096 15.82 13.7596 15.19C11.2696 13.53 7.06961 13.53 4.57961 15.19Z"
                              fill="#063540"
                            />
                            <path
                              d="M18.3392 20.75C17.9892 20.75 17.6792 20.51 17.6092 20.15C17.5292 19.74 17.7892 19.35 18.1892 19.26C18.8192 19.13 19.3992 18.88 19.8492 18.53C20.4192 18.1 20.7292 17.56 20.7292 16.99C20.7292 16.42 20.4192 15.88 19.8592 15.46C19.4192 15.12 18.8692 14.88 18.2192 14.73C17.8192 14.64 17.5592 14.24 17.6492 13.83C17.7392 13.43 18.1392 13.17 18.5492 13.26C19.4092 13.45 20.1592 13.79 20.7692 14.26C21.6992 14.96 22.2292 15.95 22.2292 16.99C22.2292 18.03 21.6892 19.02 20.7592 19.73C20.1392 20.21 19.3592 20.56 18.4992 20.73C18.4392 20.75 18.3892 20.75 18.3392 20.75Z"
                              fill="#063540"
                            />
                          </svg>
                          User Management
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    <button className="logoutbtn" onClick={Logout}>
                      <img src="\Assets\Sidebar-icons\logout.svg" alt="logout" />
                      Log Out
                    </button>
                  </Nav>
                </div>
                <Tab.Content
                  className={
                    history === "/accesscode"
                      ? "content-side accesscode-content-side"
                      : "content-side"
                  }
                >
                  <>
                    {CheckAccess("dashboard") && (
                      <Tab.Pane eventKey="first">
                        {/* <Datatable
                      setCodeDataCall={setCodeDataCall}
                      codeDataCall={codeDataCall}
                    /> */}
                        <Dashboard />
                      </Tab.Pane>
                    )}
                    {CheckAccess("access codes") && (
                      <Tab.Pane eventKey="second">
                        <Datatable
                          setCodeDataCall={setCodeDataCall}
                          codeDataCall={codeDataCall}
                        />
                      </Tab.Pane>
                    )}
                    {CheckAccess("all transactions") && (
                      <Tab.Pane eventKey="third">
                        <AllTransactions
                          setCodeDataCall={setCodeDataCall}
                          codeDataCall={codeDataCall}
                        />
                      </Tab.Pane>
                    )}
                    {CheckAccess("user management") && (
                      <Tab.Pane eventKey="forth">
                        <UserManagement
                          setCodeDataCall={setCodeDataCall}
                          codeDataCall={codeDataCall}
                        />
                      </Tab.Pane>
                    )}
                  </>
                </Tab.Content>
              </div>
            </Tab.Container>
          }
        </div>
      </section>
    </>
  );
};

export default AccessCodes;
