import React, { useEffect, useState } from "react";
import "./LeaderHome.scss";
import Navbar from "../Navbar/Navbar";
import { Accordion, Modal } from "react-bootstrap";
// import { useAccount } from 'wagmi'
import axios from "axios";
import ReactPaginate from "react-paginate";
// import { useHistory } from 'react-router-dom';
import moment from "moment";
import NavbarNew from "../Navbar/NavbarNew";
import backArrow from "../../Assests/backArrow.svg";
import shoppingCart from "../../Assests/shopping-cart.svg";
import tomiLogo from "../../Assests/tomi-logo.svg";
import moneyrecive from "../../Assests/money-recive.svg";
import earningIcon from "../../Assests/earningIcon.svg";
import externalIcon from "../../Assests/externalIcon.svg";
import { Link } from "react-router-dom";
import tomilogoicon from '../../Assests/tomi-logo-icon.svg';
import barchart from '../../Assests/bar-chart.svg';
import dollerIcon from '../../Assests/dollerIcon.svg';

const LeaderHome = (props) => {
  const [address, setAddress] = useState(null);
  const [userObj, setUserObj] = useState(null);
  const [userObj2, setUserObj2] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [rend, setRend] = useState(false);
  const [data, setData] = useState([]);
  const [totalT, setTotalT] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [purchases, setPurchases] = useState(null);
  const [earnings, setEarnings] = useState(null);
  const [page, setPage] = useState(0);
  const [usdPrice, setUsdPrice] = useState(0);

  useEffect(() => {
    if (userObj2) {
      displayData();
    }
  }, [userObj2]);

  console.log("data", data)

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // let a = JSON.stringify(inputs?.auctionCounted);
    let skip = "";
    if (e.selected > 0) {
      skip = JSON.stringify(e.selected * 10);
      setPage(selectedPage);
    } else {
      skip = JSON.stringify(0);
      setPage(0);
    }
    var data = JSON.stringify({
      query: `query MyQuery {
        investments(
          where: {code_code: "${userObj2?.code}"}
          orderDirection: desc
          first: 10
          skip: ${skip}
          orderBy: PurchaseTime
        ) {
            totalTomi
            token
            ownerFunds
            isClaimed
            id
            currentIndex
            code_userPercentage
            code_ownerPercentage
            code_ownerAddress
            code_code
            ReleaseTime
            PurchaseTime
            By
            Bonus
            AmountTomi
            AmountSpent
            trxHash
        }
      }`,
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/41280/tomi-china/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setData(response?.data?.data?.investments);
      })
      .catch(function (error) { });
  };

  const displayData = async () => {
    var data = JSON.stringify({
      query: `query MyQuery {
            investments(
              where: {code_code: "${userObj2?.code}"}
              orderDirection: desc
              first: 10
              orderBy: PurchaseTime
            ) {
                totalTomi
                token
                ownerFunds
                isClaimed
                id
                currentIndex
                code_userPercentage
                code_ownerPercentage
                code_ownerAddress
                code_code
                ReleaseTime
                PurchaseTime
                By
                Bonus
                trxHash
                AmountTomi
                AmountSpent
            }
          }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/41280/tomi-china/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        console.log("zzzzzzz", response?.data)
        setData(response?.data?.data?.investments);
      })
      .catch(function (error) {
        console.log(error, "error");
        // setstackingTime('')
      });
  };

  useEffect(() => {
    if (userObj2) {
      getTotalList();
    }
  }, [userObj2]);

  const getTotalList = async () => {
    var data = JSON.stringify({
      query: `query MyQuery {
            investments(
              where: {code_code: "${userObj2?.code}"}
              orderDirection: desc
              orderBy: PurchaseTime
            ) {
                totalTomi
                token
                ownerFunds
                isClaimed
                id
                currentIndex
                code_userPercentage
                code_ownerPercentage
                code_ownerAddress
                code_code
                ReleaseTime
                PurchaseTime
                By
                Bonus
                AmountTomi
                AmountSpent
            }
          }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.studio.thegraph.com/query/41280/tomi-china/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        console.log("zzzzzzz", response?.data)
        if (response?.data?.data?.investments?.length > 0) {
          setUserObj(response?.data?.data?.investments);
          setPageCount(response?.data?.data?.investments?.length / 10);
          let amountT = 0;
          let percUs = 0;
          let percEth = 0;
          let amoUs = 0;
          let amoEth = 0;
          for (let i of response?.data?.data?.investments) {
            let amt = (parseInt(i?.AmountTomi) / 1000000000000000000).toFixed(
              4
            );
            amountT = amountT + parseFloat(amt);
            if (i?.token === "ETH") {
              percEth =
                ((parseInt(i?.AmountSpent) / 1000000000000000000) *
                  (parseInt(i?.code_ownerPercentage) / 10)) /
                100 +
                percEth;
              amoEth = parseInt(i?.AmountSpent) / 1000000000000000000 + amoEth;
            } else {
              percUs =
                ((parseInt(i?.AmountSpent) / 1000000) *
                  (parseInt(i?.code_ownerPercentage) / 10)) /
                100 +
                percUs;
              amoUs = parseInt(i?.AmountSpent) / 1000000 + amoUs;
            }
          }
          setPurchases({
            eth: amoEth,
            us: amoUs,
          });
          setEarnings({
            eth: percEth,
            us: percUs,
          });
          setTotalT(amountT);
        }
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    axios
      .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
      .then((res) => {
        setUsdPrice(res?.data?.USD);
        // setEthUsd(res.data);
      });
  }, []);

  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    var id = val.searchParams.get("owner");

    if (id !== null) {
      setAddress(id);
      let res = localStorage.getItem("current");
      setUserObj2(JSON.parse(res));
    }
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="mainnnn leader-page-wrapper">
        <NavbarNew />
        <section className="datatable leader-home-datetable leader-gome-tbbbbbb">
          <div className="customsss-container">
            <div className="backkkk-btnn">
              <Link
                to={{
                  pathname: "/accesscode",
                  state: { index: "1", tabKey: "second" },
                }}
                className="back-link"
              >
                <button>
                  <img src={backArrow} alt="backArrow" />
                  Back
                </button>
              </Link>
              <div className="right-btns">
                <button className="rightinnerbtn black">
                  <img src="\Assets\tomitoken-assets\edit-2.svg" alt="img" />
                  Edit
                </button>
                <button className="rightinnerbtn red" onClick={handleShow}>
                  <img src="\Assets\tomitoken-assets\trash.svg" alt="img" />
                  Delete
                </button>
              </div>
              <div className="mobile-more-btn d-none">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="\Assets\navbar-assets\more-icon.svg"
                      alt="img"
                      className="img-fluid w-100"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="#">
                        <img
                          src="\Assets\navbar-assets\edit-icon.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        Edit
                      </a>
                    </li>
                    <li>
                      <a onClick={handleShow} class="dropdown-item" href="#">
                        <img
                          src="\Assets\navbar-assets\delete-icon.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        Delete
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <h2 className="upperheading">
              {userObj2?.name} <span>({userObj2?.code})</span>
            </h2>

            <div className="upperdata">
              <div className="datacard brdr1">
                <div className="dataimg">
                  <img
                    src={shoppingCart}
                    alt="datainnerimg"
                    className="datainnerimg"
                  />
                </div>
                <p className="datapara">Number of Purchases</p>
                <h6 className="datahead">{userObj?.length}</h6>
                {/* <h6 className="datahead">0</h6> */}
              </div>
              <div className="datacard brdr2">
                <div className="dataimg">
                  <img
                    src={tomilogoicon}
                    alt="datainnerimg"
                    className="datainnerimg"
                  />
                </div>
                <p className="datapara">Tomi Purchased</p>
                <h6 className="datahead">
                  {parseFloat(totalT).toLocaleString("en-US")} TOMI
                </h6>
                {/* <h6 className="datahead">0 TOMI</h6> */}
              </div>
              <div className="datacard brdr3">
                <div className="dataimg">
                  <img
                    src={dollerIcon}
                    alt="datainnerimg"
                    className="datainnerimg"
                  />
                </div>
                <p className="datapara">Purchased Amount</p>
                {usdPrice && purchases ? (
                  <h6 className="datahead">
                    ~$
                    {parseInt(
                      (usdPrice * purchases?.eth + purchases?.us)?.toFixed(2)
                    )?.toLocaleString("en-US")}{" "}
                    USD
                  </h6>
                ) : (
                  <h6 className="datahead">~$0.00 USD</h6>
                )}
                {/* <h6 className="datahead">~$0.00 USD</h6> */}
                <div className="datainfomain">
                  <div className="datainfo">
                    <span className="datainfocolor"></span>
                    <p className="datainfotext">
                      {purchases?.eth?.toFixed(6)} ETH
                    </p>
                    {/* <p className="datainfotext">0 ETH</p> */}
                  </div>
                  <div className="datainfo">
                    <span className="datainfocolortwo"></span>
                    <p className="datainfotext">
                      {parseInt(purchases?.us?.toFixed(2)).toLocaleString(
                        "en-US"
                      )}{" "}
                      USDT
                    </p>
                    {/* <p className="datainfotext">0 USDT</p> */}
                  </div>
                </div>
              </div>
              <div className="datacard brdr4">
                <div className="dataimg">
                  <img
                    src="\Assets\tomitoken-assets\newcardimg.svg"
                    alt="datainnerimg"
                    className="datainnerimg"
                  />
                </div>
                <p className="datapara">TOMI Bonuses</p>
                <h6 className="datahead">
                  {userObj2?.totalTomiBonus?.toFixed(2)} TOMI
                </h6>
              </div>
              <div className="datacard brdr5">
                <div className="dataimg">
                  <img
                    src={barchart}
                    alt="datainnerimg"
                    className="datainnerimg"
                  />
                </div>
                <p className="datapara">Total Earnings</p>
                {/* {console.log("uuuuuuuu", usdPrice, earnings)} */}
                {usdPrice && earnings ? (
                  <h6 className="datahead">
                    ~${(usdPrice * earnings?.eth + earnings?.us)?.toFixed(4)}{" "}
                    USD
                  </h6>
                ) : (
                  <h6 className="datahead">~$0.00 USD</h6>
                )}
                {/* <h6 className="datahead">~$0.00 USD</h6> */}
                <div className="datainfomain">
                  <div className="datainfo">
                    <span className="datainfocolor"></span>
                    <p className="datainfotext">
                      {earnings?.eth?.toFixed(6)} ETH
                    </p>
                    {/* <p className="datainfotext">0.21521 ETH</p> */}
                  </div>
                  <div className="datainfo">
                    <span className="datainfocolortwo"></span>
                    <p className="datainfotext">
                      {earnings?.us?.toFixed(2)} USDT
                    </p>
                    {/* <p className="datainfotext">0.02 USDT</p> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="leader-home-tab-box">
              <div className="leader-home-item-main">
                <div className="leader-home-item">
                  <p>Access Code</p>
                  <h4>{userObj2?.code}</h4>
                </div>
                <div className="leader-home-item mobile-wallet-ad">
                  <p>Owner’s Wallet Address</p>
                  {/* <h4>0x60E46C0E8572E89A...4B2A</h4> */}
                  {/* <h4>{userObj2?.ownerAddress?.slice(0, 11)}...{userObj2?.ownerAddress?.slice(userObj2?.ownerAddress?.length - 3, userObj2?.ownerAddress?.length)}</h4> */}
                  <h4>{userObj2?.ownerAddress}</h4>
                </div>
              </div>
              <div className="leader-home-item-main">
                <div className="leader-home-item">
                  <p>Name</p>
                  <h4>{userObj2?.name}</h4>
                </div>
                <div className="leader-home-item">
                  <p>Created On</p>
                  {/* <h4>30/06/2023 08:47 UTC</h4> */}
                  <h4>
                    {moment(userObj2?.createdAt)
                      .utc()
                      .format("DD/MM/YYYY hh:mm")}{" "}
                    UTC
                  </h4>
                </div>
              </div>
              <div className="leader-home-item-main">
                <div className="leader-home-item">
                  <p>Owner’s Percentage</p>
                  <h4>{userObj2?.ownerPercentage / 10}%</h4>
                </div>
                <div className="leader-home-item">
                  <p>Start Date</p>
                  {/* <h4>30/06/2023 08:47 UTC</h4> */}
                  <h4>
                    {moment(userObj2?.startDate)
                      .utc()
                      .format("DD/MM/YYYY hh:mm")}{" "}
                    UTC
                  </h4>
                </div>
              </div>
              <div className="leader-home-item-main">
                <div className="leader-home-item">
                  <p>User’s Percentage</p>
                  <h4>{userObj2?.userPercentage / 10}%</h4>
                </div>
                <div className="leader-home-item">
                  <p>End Date</p>
                  {/* <h4>30/06/2023 08:47 UTC</h4> */}
                  <h4>
                    {moment(userObj2?.endDate).utc().format("DD/MM/YYYY hh:mm")}{" "}
                    UTC
                  </h4>
                </div>
              </div>
            </div>

            <div class="table-responsivecolorbg">
              <div className="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <div className="parent-tag">
                          Date{" "}
                          <div className="filter-btn">
                            <img
                              src="\Assets\tomitoken-assets\tableup.svg"
                              alt="img"
                              className="img-fluid"
                            />
                            <img
                              src="\Assets\tomitoken-assets\tabledown.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="parent-tag">
                          Hash{" "}
                          <div className="filter-btn">
                            <img
                              src="\Assets\tomitoken-assets\tableup.svg"
                              alt="img"
                              className="img-fluid"
                            />
                            <img
                              src="\Assets\tomitoken-assets\tabledown.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="parent-tag">
                          Wallet Address{" "}
                          <div className="filter-btn">
                            <img
                              src="\Assets\tomitoken-assets\tableup.svg"
                              alt="img"
                              className="img-fluid"
                            />
                            <img
                              src="\Assets\tomitoken-assets\tabledown.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="parent-tag">
                          Amount Spent{" "}
                          <div className="filter-btn">
                            <img
                              src="\Assets\tomitoken-assets\tableup.svg"
                              alt="img"
                              className="img-fluid"
                            />
                            <img
                              src="\Assets\tomitoken-assets\tabledown.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="parent-tag">
                          tomi Purchased{" "}
                          <div className="filter-btn">
                            <img
                              src="\Assets\tomitoken-assets\tableup.svg"
                              alt="img"
                              className="img-fluid"
                            />
                            <img
                              src="\Assets\tomitoken-assets\tabledown.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="parent-tag">
                          User Bonus{" "}
                          <div className="filter-btn">
                            <img
                              src="\Assets\tomitoken-assets\tableup.svg"
                              alt="img"
                              className="img-fluid"
                            />
                            <img
                              src="\Assets\tomitoken-assets\tabledown.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="parent-tag">
                          Owner Bonus{" "}
                          <div className="filter-btn">
                            <img
                              src="\Assets\tomitoken-assets\tableup.svg"
                              alt="img"
                              className="img-fluid"
                            />
                            <img
                              src="\Assets\tomitoken-assets\tabledown.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((e, index) => {
                      let a = parseInt(e?.PurchaseTime);
                      let cc = new Date(a);
                      const StackIntialDate = moment(cc * 1000).format(
                        "DD-MM-YYYY H:mm"
                      );
                      let cc1 = new Date(parseInt(e.ReleaseTime));
                      const StackIntialDate1 = moment(cc1 * 1000).format(
                        "DD-MM-YYYY H:mm"
                      );
                      let puchased = 0;
                      if (e.token === "ETH") {
                        puchased = (parseInt(e.AmountSpent) / 10 ** 18).toFixed(
                          4
                        );
                      } else {
                        puchased = (parseInt(e.AmountSpent) / 10 ** 6).toFixed(
                          4
                        );
                      }
                      return (
                        <tr>
                          <td>{StackIntialDate}</td>
                          {/* <td>30/06/2023 08:47 UTC</td> */}
                          {/* <td>30/06/2023 08:47 UTC</td> */}
                          <td>
                            <div className="forflex">
                              <p className="forflexpara">
                                {e?.trxHash?.slice(0, 11)}...
                                {e?.trxHash?.slice(
                                  e?.trxHash?.length - 3,
                                  e?.trxHash?.length
                                )}
                              </p>

                              <a
                                href={"https://etherscan.io/tx/" + e?.trxHash}
                                target="_blank"
                              >
                                <img
                                  src={externalIcon}
                                  style={{ cursor: "pointer" }}
                                  alt="forfleximg"
                                  className="forfleximg"
                                />
                              </a>
                              {/* <p className="forflexpara">
                        0x97E6E5C8DC...79A
                      </p>
                      <a href={"https://etherscan.io/tx/"} target="_blank">
                        <img src={externalIcon} style={{ cursor: 'pointer' }} alt="forfleximg" className="forfleximg" />
                      </a> */}
                            </div>
                          </td>
                          <td>
                            {e?.By?.slice(0, 12)}...
                            {e?.By?.slice(e?.By?.length - 3, e?.By?.length)}
                          </td>
                          {/* <td>0xD84455EA10C49...60D</td> */}
                          {/* <td>2.5 ETH</td> */}
                          {e?.token === "ETH" ? (
                            <td>
                              {(
                                parseInt(e?.AmountSpent) / 1000000000000000000
                              ).toFixed(4)}{" "}
                              {e?.token}
                            </td>
                          ) : (
                            <td>
                              {(parseInt(e?.AmountSpent) / 1000000).toFixed(2)}{" "}
                              {e?.token}
                            </td>
                          )}
                          {/* <td>{e?.AmountSpent} TOMI</td> */}
                          <td>
                            {(
                              parseInt(e?.AmountTomi) / 1000000000000000000
                            ).toFixed(4)}{" "}
                            Tomi
                          </td>
                          {/* <td>1,000 TOMI</td> */}
                          <td>
                            {/* <span className='dark'>{(((parseFloat(e?.Bonus) / 1000000000000000000) / (parseInt(e?.AmountTomi) / 1000000000000000000)) * 100)?.toFixed()}% - 
                        </span> */}
                            {(
                              parseFloat(e?.Bonus) / 1000000000000000000
                            ).toFixed(4)}{" "}
                            TOMI
                          </td>
                          {/* <td>35.543 TOMI</td> */}
                          {e?.token === "ETH" ? (
                            <td>
                              {(
                                ((parseFloat(e?.AmountSpent) /
                                  1000000000000000000) *
                                  (parseInt(e?.code_ownerPercentage) / 10)) /
                                100
                              )?.toFixed(4)}{" "}
                              {e?.token}
                            </td>
                          ) : (
                            <td>
                              {(
                                ((parseFloat(e?.AmountSpent) / 1000000) *
                                  (parseInt(e?.code_ownerPercentage) / 10)) /
                                100
                              )?.toFixed(2)}{" "}
                              {e?.token}
                            </td>
                          )}
                          {/* <td>0.012 ETH</td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="accmblview d-none">
                {data?.map((e, index) => {
                  let a = parseInt(e?.PurchaseTime);
                  let cc = new Date(a);
                  const StackIntialDate = moment(cc * 1000).format(
                    "DD-MM-YYYY H:mm"
                  );
                  let cc1 = new Date(parseInt(e.ReleaseTime));
                  const StackIntialDate1 = moment(cc1 * 1000).format(
                    "DD-MM-YYYY H:mm"
                  );
                  let puchased = 0;
                  if (e.token === "ETH") {
                    puchased = (parseInt(e.AmountSpent) / 10 ** 18).toFixed(4);
                  } else {
                    puchased = (parseInt(e.AmountSpent) / 10 ** 6).toFixed(4);
                  }
                  return (
                    <Accordion defaultActiveKey={0}>
                      <Accordion.Item eventKey={0}>
                        <Accordion.Header>
                          <div className="accheadermain">
                            <div className="header-left-box">
                              <p className="header-inner-top">My Earning</p>
                              {e?.token === "ETH" ? (
                                <p className="header-inner-bottom">
                                  {(
                                    ((parseInt(e?.AmountSpent) /
                                      1000000000000000000) *
                                      parseInt(e?.code_ownerPercentage)) /
                                    100
                                  )?.toFixed(4)}{" "}
                                  {e?.token}
                                </p>
                              ) : (
                                <p className="header-inner-bottom">
                                  {(
                                    ((parseInt(e?.AmountSpent) / 1000000) *
                                      parseInt(e?.code_ownerPercentage)) /
                                    100
                                  )?.toFixed(2)}{" "}
                                  {e?.token}
                                </p>
                              )}
                              {/* <p className="header-inner-bottom">0.4155</p> */}
                            </div>
                            <div className="header-right-box">
                              <p className="header-inner-top">tomi Purchased</p>
                              <p className="header-inner-bottom">
                                {/* {StackIntialDate} */}
                                1,000 TOMI
                              </p>
                              {/* <p className='header-inner-bottom'>30/06/2023 08:47 UTC</p> */}
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="acctexts">
                            <h6 className="textleft">Date & Time</h6>
                            <p className="textright">30/06/2023 08:47 UTC</p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Hash</h6>
                            <p className="textright">
                              {e?.trxHash?.slice(0, 11)}...
                              {e?.trxHash?.slice(
                                e?.trxHash?.length - 3,
                                e?.trxHash?.length
                              )}
                              <a
                                href={"https://etherscan.io/tx/" + e?.trxHash}
                                target="_blank"
                              >
                                <img src={externalIcon} alt="img" />
                              </a>
                            </p>
                            {/* <p className="textright">0x04554845
                        <a href={"https://etherscan.io/tx/"} target="_blank">
                          <img src={externalIcon} alt="img" />
                        </a>
                      </p> */}
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Wallet Address</h6>
                            <p className="textright">
                              {e?.By?.slice(0, 12)}...
                              {e?.By?.slice(e?.By?.length - 3, e?.By?.length)}
                              <img
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  navigator.clipboard.writeText(e?.By);
                                }}
                                src="\Assets\tomitoken-assets\copyy.svg"
                                alt="img"
                              // className="me-2"
                              />
                            </p>
                            {/* <p className="textright">0xD84455EA10C491...60D</p> */}
                          </div>
                          {/* <div className="acctexts">
                            <h6 className="textleft">tomi Purchased</h6>
                            <p className="textright">{(parseInt(e?.AmountTomi) / 1000000000000000000).toFixed(4)} {e?.token}</p>
                            <h6>15.14844</h6>
                            <p className="textright">1,000 TOMI</p>
                          </div> */}
                          <div className="acctexts">
                            <h6 className="textleft">Amount Spent</h6>
                            {e?.token === "ETH" ? (
                              <p className="textright">
                                {(
                                  ((parseInt(e?.AmountSpent) /
                                    1000000000000000000) *
                                    parseInt(e?.code_ownerPercentage)) /
                                  100
                                )?.toFixed(4)}{" "}
                                {e?.token}
                              </p>
                            ) : (
                              <p className="textright">
                                {(
                                  ((parseInt(e?.AmountSpent) / 1000000) *
                                    parseInt(e?.code_ownerPercentage)) /
                                  100
                                )?.toFixed(2)}{" "}
                                {e?.token}
                              </p>
                            )}
                            {/* <p className="textright">2.5 ETH</p> */}
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">User's Bonus</h6>
                            {/* <p className="textright"> <span className="dark">8% - </span>0.2548 TOMI</p> */}
                            {/* <td><span className='dark'>{(((parseFloat(e?.Bonus) / 1000000000000000000) / (parseInt(e?.AmountTomi) / 1000000000000000000)) * 100)?.toFixed()}% - </span>{(parseFloat(e?.Bonus) / 1000000000000000000).toFixed(4)} TOMI</td> */}
                            {/* <td><span className='dark'>215% - </span>0.2548 TOMI</td> */}
                            <p className="textright">
                              {/* <span className="dark">
                              {(((parseFloat(e?.Bonus) / 1000000000000000000) / (parseInt(e?.AmountTomi) / 1000000000000000000)) * 100)?.toFixed()}% </span>-  */}
                              {(
                                parseFloat(e?.Bonus) / 1000000000000000000
                              ).toFixed(4)}{" "}
                              TOMI
                            </p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Owner's Bonus</h6>
                            <p className="textright">0.012 ETH</p>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })}
              </div>
              {/* {pageCount >= 1 ? ( */}
              <div className="footer-content">
                <div className="left-f">
                  {/* <h6>SHOWING {page + 1}-{10} OF {total}</h6> */}
                </div>
                <div className="right-f">
                  <nav aria-label="Page navigation example">
                    <div className="text-center">
                      <ReactPaginate
                        previousLabel={
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path d="M10 13L5 8L10 3" stroke="#A0A1A1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        }
                        nextLabel={
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path d="M5 13L10 8L5 3" stroke="#FF0083" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        }
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={page}
                      ></ReactPaginate>
                    </div>
                    {/* <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="#"><img src='\Assets\tomitoken-assets\arrow1.svg' alt='img' className='img-fluid' /></a></li>
                    <li class="page-item"><a class="page-link active" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                    <li class="page-item"><a class="page-link" href="#"><img src='\Assets\tomitoken-assets\arrow2.svg' alt='img' className='img-fluid' /></a></li>
                  </ul> */}
                  </nav>
                </div>
              </div>
              {/* ) : (
              ""
            )} */}
            </div>
          </div>
        </section>
      </div>

      <Modal className="deletemodal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src="\Assets\tomitoken-assets\delete 1.svg"
            alt="deleteimg"
            className="deleteimg"
          />
          <p className="modalparamained">
            Are you sure you want to delete this?
          </p>
          <p className="modalparaaa">
            This will delete it permanently. You cannot undo this action.
          </p>
          <div className="modalbtns">
            <button className="cancelbtn">Cancel</button>
            <button className="deletebtn">Delete</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeaderHome;
